// StockPage.tsx
import React from 'react';
import { Box, Heading, Text, useColorModeValue, Image, Stack, Button } from '@chakra-ui/react';
import StockDropdownImage from 'assets/img/docs/stockDropdown.png';
import AdjustStockItemDialog from 'assets/img/docs/adjustStockItemDialog.png';
import StockTableImage from 'assets/img/docs/stockTable.png';
import StockMovementsTable from 'views/admin/stock/movements/components/stockMovementTable';
import { stockMovements } from '../mockdata/exampleStockTableData';


const StockPage: React.FC = () => {
  const headingColor = useColorModeValue('gray.700', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Box>
    <Heading as="h3" size="lg" color={headingColor} mb={4}>
      Stock
    </Heading>
    <Text color={textColor} fontSize="md" mb={4}>
      These are the things that your business owns and are available for sale to customers. This is an important distinction to inventory, which is everything.
      You don't directly add items as 'stock' to your inventory, but rather it's any item with a selling price.
      The stock page itself contains two sub-pages:
    </Text>
    <Text color={textColor} fontSize="md" mb={4}>
    <Stack alignContent={'center'} alignItems={'center'}>
    <Image 
    src={StockDropdownImage}
    />
    </Stack>
    </Text>
    <Text color={textColor} fontSize="lg" mb={4}>
      <strong> Stock Levels</strong>
    </Text>
    <Text color={textColor} fontSize="md" mb={4}>
      This page will allow you to see the your current stock items and information about them. 
      It will also display the total stock value, total value of stock sales this month, the number of jobs involving stock and the total number of stock items.  
    </Text>
    <Text color={textColor} fontSize="md" mb={4}>
      <strong>Actions available on this page:</strong>
    </Text>
           <Text color={textColor} fontSize="md" mb={4}> <Button
              variant="brand"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '200px', md: '300px' }}
              h="32px"
              aria-label="Add Item"
            >
              Manual Stock Adjustment
            </Button>
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
            This button will bring a dialog to allow you to adjust stock levels manually, you will need to provide a reason for this for auditting purposes.
            Generally this would be taken care off by adding a stock item to a job; but sometimes things get dropped / go missing / etc so we've got you covered:
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
             <Image src={AdjustStockItemDialog} />
             <i>As we allow for addition and removal on this form, you should <strong>add a minus sign to indicate it should be removed</strong></i>
            </Text>
            
            <Text color={textColor} fontSize="md" mb={4}>
             <strong>Stock table</strong>
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
            This table will display the stock items you currently have in your business:
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
              <Image src={StockTableImage} />
                  </Text>
                  <Text color={textColor} fontSize="md" mb={4}>
                    The <strong>EDIT</strong> button will allow you to edit the item.
                  </Text>
                  <Text color={textColor} fontSize="md" mb={4}>
                    The <strong>DELETE</strong> button will allow you to delete the item.
                  </Text>
                  <Text color={textColor} fontSize="lg" mb={4}>
      <strong> Stock Movements </strong>
    </Text>
    <Text color={textColor} fontSize="md" mb={4}>
      This page will allow you to view all the stock movements in your business, this can be manually added by you or automatically added by jobs:
      </Text>
    <Text color={textColor} fontSize="md" mb={4}>
      <StockMovementsTable tableData={stockMovements} />
    </Text>
  </Box>
  );
};

export default StockPage;