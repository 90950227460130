import { Box, SimpleGrid } from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Category, CategoriesResponse } from "components/types/categories";
import CategoriesTable from "views/admin/inventory/categories/components/categoriesTable";
import { useApi } from "components/hooks/useApi";
import { useToken } from "components/hooks/useToken";
import { useTenant } from "components/hooks/useTenant";
import Loading from "components/Loading";

export default function AllItems() {
  const [tableData, setTableData] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const apiUrl = useApi();
  const tenant = useTenant();
  const accessToken = useToken();
  const refetchData = useCallback(async () => {
    try {
      const response = await axios.get<CategoriesResponse>(
        `${apiUrl}/categories`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            tenant: tenant,
          },
        }
      );

      
      const fetchedCategories = response.data.data.Categories;
      if (Array.isArray(fetchedCategories)) {
        setTableData(fetchedCategories);
      } else {
        setTableData([]); 
        console.error("Categories data is not an array.");
      }

      setLoading(false);
      setError(null);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message ||
            "An error occurred while fetching data"
        );
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      setLoading(false);
      setTableData([]);
    }
  }, [apiUrl, accessToken, tenant]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);


  if (loading) return <Loading message="Loading data..." />;
  if (error) return <p>Error: {error}</p>;
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <CategoriesTable tableData={tableData} refetchData={refetchData} accessToken={accessToken} tenant={tenant} apiUrl={apiUrl}/>
      </SimpleGrid>
    </Box>
  );
}
