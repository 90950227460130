import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export function useToken() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (isMounted) {
          setToken(token);
        }
      } catch (err) {
        console.error('Error fetching token:', err);
      }
    };

    fetchToken();
    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  return token;
}
