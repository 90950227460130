// src/views/admin/stock/movements/StockMovements.tsx
import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import axios from "axios";
import StockMovementsTable, { Movement } from "./components/stockMovementTable";
import Loading from "components/Loading";
import { useApi } from "components/hooks/useApi";
import { useToken } from "components/hooks/useToken";
import { useTenant } from "components/hooks/useTenant";

export default function StockMovements() {
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const [movements, setMovements] = useState<Movement[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [itemsMap, setItemsMap] = React.useState<{ [key: number]: string }>({});

  function formatRelativeDate(dateStr: string): string {
    const date = new Date(dateStr);
    const now = new Date();
  
    // Helper to get a date without time components
    const startOfDay = (d: Date) => new Date(d.getFullYear(), d.getMonth(), d.getDate());
  
    const today = startOfDay(now);
    const targetDay = startOfDay(date);
  
    // Calculate difference in days between target day and today
    const msInDay = 1000 * 60 * 60 * 24;
    const dayDiff = Math.round((today.getTime() - targetDay.getTime()) / msInDay);
  
    let prefix: string;
    if (dayDiff === 0) prefix = "Today";
    else if (dayDiff === 1) prefix = "Yesterday";
    else if (dayDiff === -1) prefix = "Tomorrow";
    else prefix = targetDay.toLocaleDateString(); // Fallback: show full date for other cases
  
    // Format the time component
    const timeStr = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${prefix}, ${timeStr}`;
  }

  const fetchItems = useCallback(async (): Promise<void> => {
    if (!accessToken || !tenant) return;
  
    try {
      const response = await axios.get<{ data: { Items: Array<{ id: number; name: string }> } }>(
        `${apiUrl}/inventory/stock`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: { tenant },
        }
      );
      // Build the mapping from item_id to item_name
      const itemsArray = response.data.data.Items;
      const map: { [key: number]: string } = {};
      itemsArray.forEach(item => {
        map[item.id] = item.name;
      });
      setItemsMap(map);
    } catch (err: unknown) {
      // Handle errors if needed
    }
  }, [accessToken, tenant, apiUrl]);

  React.useEffect(() => {
    fetchItems();
  }, [fetchItems]);


  const fetchData = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get<{ data: { StockMovements: any[] } }>(
        `${apiUrl}/stock_movements`, 
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
  
      const apiMovements = response.data.data.StockMovements;

      if (!apiMovements || apiMovements.length === 0) {
        setMovements([]);
        setError(null);
        setLoading(false);
        return;
      }

      apiMovements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

      const transformedMovements: Movement[] = apiMovements.map((m) => ({
        date: formatRelativeDate(m.created_at),  // Use relative date here
        sum: m.quantity > 0 ? `+${m.quantity}` : `${m.quantity}`,
        name: itemsMap[m.item_id] || `Item ${m.item_id}`,  
        reason: m.reason,
      }));
  
      setMovements(transformedMovements);
      setError(null);
    } catch (err: any) {
      setError(err.response?.data?.message || "Error fetching movements");
    } finally {
      setLoading(false);
    }
  }, [accessToken, tenant, apiUrl, itemsMap]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      {loading ? (
        <Loading message="Loading data ..."/>
      ) : error ? (
        <p>Error: {error}</p>
      ) : movements.length === 0 ? (
        <p>No stock movements found.</p>
      ) : (
        <StockMovementsTable tableData={movements} />
      )}
    </Box>
  );
}
