export const exampleItemsTableData = [
    {
        id: 1,
        name: 'Summer Blend Honey',
        supplier_id: 2,
        quantity: 15,
        cost_price: 3.5,
        selling_price: 6.99,
        min_level: 5,
        batch_number: 'BCH-001',
        tenant_id: 1,
        expiry_date: '2023-11-15',
        category_name: 'Honey Products',
        created_at: '2023-01-01',
        updated_at: '2023-01-01',
      },
      {
        id: 2,
        name: 'Propolis Tincture',
        supplier_id: 3,
        quantity: 25,
        cost_price: 2.99,
        selling_price: 5.99,
        min_level: 10,
        batch_number: 'BCH-002',
        tenant_id: 1,
        expiry_date: '2024-03-20',
        category_name: 'Health Supplements',
        created_at: '2023-01-05',
        updated_at: '2023-01-10',
      },
      {
        id: 3,
        name: 'Beeswax Candles (Small)',
        supplier_id: 4,
        quantity: 40,
        cost_price: 1.5,
        selling_price: 3.5,
        min_level: 8,
        batch_number: 'BCH-003',
        tenant_id: 1,
        expiry_date: '2025-01-01',
        category_name: 'Candles & Wax',
        created_at: '2023-01-15',
        updated_at: '2023-02-02',
      },
      {
        id: 4,
        name: 'Lip Balm (Lavender)',
        supplier_id: 2,
        quantity: 30,
        cost_price: 0.99,
        selling_price: 2.49,
        min_level: 5,
        batch_number: 'BCH-004',
        tenant_id: 1,
        expiry_date: '2024-08-01',
        category_name: 'Personal Care',
        created_at: '2023-01-25',
        updated_at: '2023-01-25',
      },
      {
        id: 5,
        name: 'Royal Jelly Capsules',
        supplier_id: 5,
        quantity: 10,
        cost_price: 4.99,
        selling_price: 8.99,
        min_level: 3,
        batch_number: 'BCH-005',
        tenant_id: 1,
        expiry_date: '2025-06-15',
        category_name: 'Health Supplements',
        created_at: '2023-02-01',
        updated_at: '2023-02-10',
      },
      {
        id: 6,
        name: 'Pollen Granules',
        supplier_id: 3,
        quantity: 50,
        cost_price: 3.25,
        selling_price: 6.25,
        min_level: 10,
        batch_number: 'BCH-006',
        tenant_id: 1,
        expiry_date: '2024-12-31',
        category_name: 'Health Supplements',
        created_at: '2023-02-05',
        updated_at: '2023-02-05',
      },
]