// src/components/dataDisplay/AddCategoryForm.tsx

import React, { useState } from 'react';
import {
  Button,
  Textarea,
  Stack,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useTenant } from 'components/hooks/useTenant';
import { useToken } from 'components/hooks/useToken';

interface AddCategoryFormProps {
  /** Function to refetch or refresh the list of categories in the parent */
  refreshCategories: () => void;
}

export default function AddCategoryForm({ refreshCategories }: AddCategoryFormProps) {
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();

  // 1) Modal open/close controls
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 2) This will store the user's typed lines (one category per line)
  const [multipleNames, setMultipleNames] = useState('');

  // 3) Handler for the "Add Categories" action
  const handleAddMultiple = async () => {
    try {
      // Split text by newlines into an array of trimmed strings
      const namesArray = multipleNames
        .split('\n')
        .map((n) => n.trim())
        .filter((n) => n !== '');

      if (namesArray.length === 0) {
        toast({
          title: 'Validation Error',
          description: 'Please enter at least one category name.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Build the payload for your backend
      const payload = {
        Categories: namesArray.map((name) => ({ name })),
      };

      await axios.post(`${apiUrl}/categories`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          tenant: tenant,
        },
      });

      toast({
        title: 'Categories Added',
        description: `Successfully added ${namesArray.length} categories.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Clear the textarea
      setMultipleNames('');
      // Close the modal
      onClose();
      // Refresh the category list in the parent
      refreshCategories();
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error creating categories.',
        description: 'Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // 4) Optionally reset the input on close
  const handleModalClose = () => {
    setMultipleNames('');
    onClose();
  };

  return (
    <>
      {/* The Button to open this "Add Categories" modal */}
      <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        w={{ base: '100px', md: '150px' }}
        h="32px"
        onClick={onOpen}
        aria-label="Add Item"
      >
        Add Categories
      </Button>

      <Modal isOpen={isOpen} onClose={handleModalClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add multiple categories</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Enter one category per line</FormLabel>
              <Textarea
                placeholder="Category 1"
                value={multipleNames}
                onChange={(e) => setMultipleNames(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleModalClose}>
              Cancel
            </Button>
            <Button variant="brand" onClick={handleAddMultiple}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
