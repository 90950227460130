// src/views/admin/jobs/components/AddJobModal.tsx
import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Stack,
  useDisclosure,
  useToast,
  Box,
  Select,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
import axios from 'axios';
import InputField from 'components/fields/InputField';
import DayDateField from 'components/fields/DayDateField';
// (optional) Maybe import a "AddItemModal" if you reuse items
// import AddItemModal from 'views/admin/inventory/allitems/components/addItemModal';

interface AddJobModalProps {
  refreshJobs: () => void;
}

interface JobItemRow {
  item_id: string;
  quantity: string;
}

interface ItemData {
  id: number;
  name: string;
}

export default function AddJobModal({ refreshJobs }: AddJobModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();

  const [name, setName] = useState('');
  const [jobDate, setJobDate] = useState<Date | null>(new Date());
  const [status, setStatus] = useState('Open');
  const [jobItems, setJobItems] = useState<JobItemRow[]>([
    { item_id: '', quantity: '' },
  ]);

  const [items, setItems] = useState<ItemData[]>([]);
  const [loadingItems, setLoadingItems] = useState(false);

  // 1) Fetch items so user can pick them
  const fetchItems = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      setLoadingItems(true);
      const res = await axios.get<{ data: { Items: ItemData[] } }>(
        `${apiUrl}/inventory/items`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      setItems(res.data.data.Items || []);
    } catch (err) {
      console.error('Error fetching items for job creation:', err);
    } finally {
      setLoadingItems(false);
    }
  }, [apiUrl, accessToken, tenant]);

  useEffect(() => {
    if (isOpen) {
      fetchItems();
    }
  }, [isOpen, fetchItems]);

  // 2) Handlers for jobItems
  const handleJobItemChange = (
    index: number,
    field: keyof JobItemRow,
    value: string
  ) => {
    setJobItems((prev) => {
      const newArr = [...prev];
      newArr[index] = { ...newArr[index], [field]: value };
      return newArr;
    });
  };

  const addJobItemRow = () => {
    setJobItems((prev) => [...prev, { item_id: '', quantity: '' }]);
  };
  const removeJobItemRow = (index: number) => {
    setJobItems((prev) => prev.filter((_, i) => i !== index));
  };

  // 3) Submit job
  const handleSubmit = async () => {
    if (!name.trim() || !jobDate) {
      toast({
        title: 'Validation Error',
        description: 'Name and Date are required.',
        status: 'warning',
        duration: 3000,
      });
      return;
    }
    // If jobItems are required, check them
    for (const row of jobItems) {
      if (!row.item_id || !row.quantity) {
        toast({
          title: 'Validation Error',
          description: 'Every job item must have an item and quantity',
          status: 'warning',
        });
        return;
      }
    }

    try {
      // Build the payload as your backend expects
      const payload = {
        jobs: [
          {
            tenant_id: parseInt(tenant?.toString() || '0'),
            name,
            status,
            date: jobDate,
            items: jobItems.map((row) => ({
              item_id: parseInt(row.item_id, 10),
              quantity: parseInt(row.quantity, 10),
            })),
          },
        ],
      };

      await axios.post(`${apiUrl}/jobs`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });
      toast({
        title: 'Job Created',
        status: 'success',
        duration: 3000,
      });
      // Reset
      setName('');
      setJobDate(new Date());
      setStatus('Open');
      setJobItems([{ item_id: '', quantity: '' }]);
      onClose();
      refreshJobs();
    } catch (err: any) {
      toast({
        title: 'Error creating job',
        description:
          err.response?.data?.message || 'Something went wrong with job creation.',
        status: 'error',
      });
    }
  };

  return (
    <>
      <Button         
      variant="brand"
      fontSize="sm"
      borderRadius="16px"
      h="32px"
      onClick={onOpen}>
        Add New Job
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a New Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="20px">
              <InputField
                label="Job Name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
                isRequired
              />
              <DayDateField
                label="Date"
                value={jobDate}
                onChange={(val) => setJobDate(val)}
                isRequired
              />
              <InputField
                label="Status"
                value={status}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setStatus(e.target.value)
                }
              />

              {/* JOB ITEMS */}
              <Box borderWidth="1px" borderRadius="md" p="4">
                <Stack spacing="4">
                  {jobItems.map((row, idx) => (
                    <Stack
                      key={idx}
                      direction="row"
                      spacing="10px"
                      borderWidth="1px"
                      p="4"
                      borderRadius="md"
                    >
                      <Select
                        placeholder="Select an item"
                        value={row.item_id}
                        onChange={(e) =>
                          handleJobItemChange(idx, 'item_id', e.target.value)
                        }
                        isDisabled={loadingItems}
                      >
                        {items.map((it) => (
                          <option key={it.id} value={it.id}>
                            {it.name}
                          </option>
                        ))}
                      </Select>

                      <InputField
                        label="Qty"
                        type="number"
                        placeholder="Quantity"
                        value={row.quantity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleJobItemChange(idx, 'quantity', e.target.value)
                        }
                      />

                      {jobItems.length > 1 && (
                        <IconButton
                          aria-label="Remove Item"
                          icon={<CloseIcon />}
                          colorScheme="red"
                          onClick={() => removeJobItemRow(idx)}
                          alignSelf="flex-end"
                        />
                      )}
                    </Stack>
                  ))}
                  <Button onClick={addJobItemRow} variant="outline">
                    Add Another Item
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="brand" onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
