import { Icon } from '@chakra-ui/react';
import {
  MdAdminPanelSettings
} from 'react-icons/md';

// Administration actions imports

import PlatformStats from 'views/admin/administration/platformstats';
import ManageUsers from 'views/admin/administration/usermanagement';
import ManageTenants from 'views/admin/administration/tenantmanagement';
const administrationRoutes = [

// --- admin tasks --- 
  {
  name: 'Admin',
  path: '/administration',
  icon: <Icon as={MdAdminPanelSettings} width="20px" height="20px" color="inherit" />,
  collapse: true,
  items: [
    {
      name: 'Manage Users',
      layout: '/admin',
      path: '/administration/usermanagement',
      component: <ManageUsers/>
    },
    {
      name: 'Manage Tenants',
      layout: '/admin',
      path: '/administration/tenantmanagement',
      component: <ManageTenants />
    },
    {
      name: 'Platform Stats',
      layout: '/admin',
      path: '/administration/platformstats',
      component: <PlatformStats />
    },
  ]
  },
];

export default administrationRoutes;
