// src/pages/AllItems.tsx

import React, { useEffect, useState, useCallback } from "react";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import ItemTable from "components/dataDisplay/ItemTable";
import InventoryHeader from "./components/inventoryHeader";
import { Item, ItemsResponse } from "components/types/items";
import axios from "axios";
import { useTenant } from "components/hooks/useTenant";
import { useToken } from "components/hooks/useToken";
import { useApi } from "components/hooks/useApi";
import Loading from "components/Loading";
import AddItemModal from "./components/addItemModal";
import AddSupplierForm from "views/admin/orders/suppliers/components/addSupplierForm";
import AddCategoryForm from "../categories/components/addCategoryModal";
export default function AllItems() {
  const accessToken = useToken();
  const tenant = useTenant();
  const apiUrl = useApi();
  const [tableData, setTableData] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  

  const fetchData = useCallback(async (): Promise<void> => {
    if (!accessToken || !tenant) return; // Ensure required data exists

    try {
      const response = await axios.get<ItemsResponse>(
        `${apiUrl}/inventory/items`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            tenant: tenant,
          },
        }
      );
      setTableData(response.data.data.Items);
      setError(null);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || "An error occurred while fetching data");
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  }, [accessToken, tenant, apiUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Loading message="Fetching items..." />;
  }

  if (error) return <p>Error: {error}</p>;

  return (
    <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
      <InventoryHeader />
      <Stack direction="row" align="center" w="100%">
      <AddItemModal refreshItems={fetchData} />
      <AddSupplierForm refreshSuppliers={fetchData} />
      <AddCategoryForm refreshCategories={fetchData} />
      </Stack>
      <ItemTable 
        tableData={tableData} 
        tableName="Inventory" 
        accessToken={accessToken} 
        tenant={tenant}
        refreshItems={fetchData}
      />
    </SimpleGrid>
  );
}
