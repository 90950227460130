import {Flex, Icon } from '@chakra-ui/react';
import { BsArrowsAngleExpand } from 'react-icons/bs';
export default function SidebarDocs(props: {
  mini: boolean;
  hovered: boolean;
}) {
  const { mini, hovered } = props;
  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      bgGradient="linear(to-b, brand.400, brand.600)"
      borderRadius="20px"
      me="20px"
      position="relative"
    >
      <Icon
        display={mini === true && hovered === false ? 'block' : 'none'}
        h="26px"
        w="26px"
        my="100px"
        mx="20px"
        color="white"
        as={BsArrowsAngleExpand}
      />

    </Flex>
  );
}
