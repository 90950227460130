// src/pages/SuppliersPage.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import axios from 'axios';

import SupplierTable from "components/dataDisplay/SupplierTable";
import AddSupplierForm from "./components/addSupplierForm";
import Loading from 'components/Loading'; // Ensure you have a Loading component
import { useApi } from "components/hooks/useApi";
import { useToken } from "components/hooks/useToken";
import { useTenant } from "components/hooks/useTenant";

// Define Supplier type
interface Supplier {
  id: number;
  name: string;
  contact_email: string;
  contact_phone: string;
  address: string;
  // Add other fields if necessary
}

interface SuppliersResponse {
  data: {
    Suppliers: Supplier[];
  };
}

export default function SuppliersPage() {
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();

  const [supplierData, setSupplierData] = useState<Supplier[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchSuppliers = useCallback(async () => {
    if (!accessToken || !tenant) return;

    setLoading(true);
    try {
      const response = await axios.get<SuppliersResponse>(`${apiUrl}/suppliers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          tenant: tenant,
        },
      });

      const fetchedSuppliers = response.data?.data?.Suppliers;
      if (Array.isArray(fetchedSuppliers)) {
        setSupplierData(fetchedSuppliers);
      } else {
        setSupplierData([]);
        console.warn('Suppliers data is not an array.');
      }
      setError(null);
    } catch (err: any) {
      setError(err.response?.data?.message || 'An error occurred while fetching suppliers.');
      setSupplierData([]);
    } finally {
      setLoading(false);
    }
  }, [apiUrl, accessToken, tenant]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  if (loading) return <Loading message="Loading suppliers..." />;
  if (error) return <Text color="red.500" textAlign="center">{error}</Text>;

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid gap="20px">
        <AddSupplierForm refreshSuppliers={fetchSuppliers} />
        <SupplierTable 
          tableData={supplierData} 
          tableName="Suppliers" 
          refreshSuppliers={fetchSuppliers}
          accessToken={accessToken}
          tenant={tenant}
        />
      </SimpleGrid>
    </Box>
  );
}
