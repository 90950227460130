// src/components/modals/EditItemModal.tsx

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast
} from '@chakra-ui/react';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import DateField from 'components/fields/DateField';

interface EditItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: Item | null;
  accessToken: string;
  tenant: string | number;
  refreshItems: () => void;
}

interface Item {
  id: number;
  name: string;
  category_name: string;  // We'll store the category_name directly
  supplier_id?: number | null;
  quantity: number;
  cost_price: number;
  selling_price: number;
  min_level: number;
  expiry_date: string;
  batch_number: string;
}

interface Category {
  id: number;
  name: string;
}

interface Supplier {
  id: number;
  name: string;
}

const EditItemModal: React.FC<EditItemModalProps> = ({
  isOpen,
  onClose,
  item,
  accessToken,
  tenant,
  refreshItems,
}) => {
  const toast = useToast();
  const apiUrl = useApi();

  // Local state for form fields
  const [formData, setFormData] = useState<Partial<Item>>({});

  // Local lists for categories / suppliers
  const [categories, setCategories] = useState<Category[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  /**
   * When the modal opens with a specific item, load its data into local form state
   */
  useEffect(() => {
    if (item) {
      // Spread the existing item data so we can edit it
      setFormData({ ...item });
    }
  }, [item]);

  /**
   * Fetch categories from your API
   */
  const fetchCategories = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get(`${apiUrl}/categories`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });

      const fetchedCategories: Category[] = response.data?.data?.Categories ?? [];
      setCategories(fetchedCategories);
    } catch (error) {
      toast({
        title: 'Error Fetching Categories',
        description: 'Could not load category list.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setCategories([]);
    }
  }, [accessToken, tenant, apiUrl, toast]);

  /**
   * Fetch suppliers from your API
   */
  const fetchSuppliers = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get(`${apiUrl}/suppliers`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });

      const fetchedSuppliers: Supplier[] = response.data?.data?.Suppliers ?? [];
      setSuppliers(fetchedSuppliers);
    } catch (error) {
      toast({
        title: 'Error Fetching Suppliers',
        description: 'Could not load supplier list.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setSuppliers([]);
    }
  }, [accessToken, tenant, apiUrl, toast]);

  /**
   * Fetch categories & suppliers on mount (or when token/tenant changes)
   */
  useEffect(() => {
    fetchCategories();
    fetchSuppliers();
  }, [fetchCategories, fetchSuppliers]);

  /**
   * Helper to update fields in the local form data
   */
  const handleChange = (field: keyof Item, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  /**
   * PUT request to save updates
   */
  const handleSave = async () => {
    if (!item) return;

    try {
      // Build the payload with updated fields, fallback to original item values
      const payload = {
        items: [
          {
            id: item.id,
            name: formData.name ?? item.name,
            category_name: formData.category_name ?? item.category_name,
            supplier_id: formData.supplier_id ?? item.supplier_id,
            quantity: formData.quantity ?? item.quantity,
            cost_price: formData.cost_price ?? item.cost_price,
            selling_price: formData.selling_price ?? item.selling_price,
            min_level: formData.min_level ?? item.min_level,
            expiry_date: formData.expiry_date ?? item.expiry_date,
            batch_number: formData.batch_number ?? item.batch_number,
          },
        ],
      };

      const response = await axios.put(`${apiUrl}/inventory/items`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: { tenant },
      });

      if (response.status === 200) {
        toast({
          title: 'Item updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
        refreshItems();
      } else {
        throw new Error('Update failed');
      }
    } catch (error: any) {
      console.error(error);
      toast({
        title: 'Failed to update item',
        description:
          error.response?.data?.message || 'An error occurred while updating the item.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Item Name */}
          <FormControl mb={3}>
            <FormLabel>Name</FormLabel>
            <Input
              value={formData.name || ''}
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </FormControl>

          {/* Category Name (Dropdown) */}
          <FormControl mb={3}>
            <FormLabel>Category</FormLabel>
            <Select
              placeholder={
                categories.length > 0 ? 'Select Category' : 'No categories available'
              }
              value={formData.category_name || ''}
              onChange={(e) => handleChange('category_name', e.target.value)}
              isDisabled={categories.length === 0}
            >
              {categories.map((cat) => (
                <option key={cat.id} value={cat.name}>
                  {cat.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Supplier (Dropdown) */}
          <FormControl mb={3}>
            <FormLabel>Supplier</FormLabel>
            <Select
              placeholder={
                suppliers.length > 0 ? 'Select Supplier' : 'No suppliers available'
              }
              value={formData.supplier_id || ''}
              onChange={(e) => handleChange('supplier_id', parseInt(e.target.value, 10))}
              isDisabled={suppliers.length === 0}
            >
              {suppliers.map((sup) => (
                <option key={sup.id} value={sup.id}>
                  {sup.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Quantity */}
          <FormControl mb={3}>
            <FormLabel>Quantity</FormLabel>
            <Input
              type="number"
              value={formData.quantity ?? ''}
              onChange={(e) => handleChange('quantity', parseInt(e.target.value, 10))}
            />
          </FormControl>

          {/* Cost Price */}
          <FormControl mb={3}>
            <FormLabel>Cost Price</FormLabel>
            <Input
              type="number"
              step="0.01"
              value={formData.cost_price ?? ''}
              onChange={(e) => handleChange('cost_price', parseFloat(e.target.value))}
            />
          </FormControl>

          {/* Selling Price */}
          <FormControl mb={3}>
            <FormLabel>Selling Price</FormLabel>
            <Input
              type="number"
              step="0.01"
              value={formData.selling_price ?? ''}
              onChange={(e) => handleChange('selling_price', parseFloat(e.target.value))}
            />
          </FormControl>

          {/* Minimum Level (min_level) */}
          <FormControl mb={3}>
            <FormLabel>Minimum Level</FormLabel>
            <Input
              type="number"
              value={formData.min_level ?? ''}
              onChange={(e) => handleChange('min_level', parseInt(e.target.value, 10))}
            />
          </FormControl>

          {/* Expiry Date */}
          <FormControl mb={3}>
            <FormLabel>Expiry Date</FormLabel>
            {/* If you have a reusable DateField component, you can use it.
                Or just use an <Input type="date" /> if you prefer */}
            <DateField
              value={formData.expiry_date ? new Date(formData.expiry_date) : null}
              onChange={(date) =>
                handleChange('expiry_date', date ? date.toISOString() : '')
              }
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="brand" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditItemModal;
