import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Stack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Flex, 
  SimpleGrid,
  IconButton
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import DateField from 'components/fields/DateField';
import Card from 'components/card/Card';
import axios from 'axios';
import { useTenant } from 'components/hooks/useTenant';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';

interface AddItemModalProps {
  refreshItems: () => void;
  isOpen?: boolean;
  onClose?: () => void;
}

export default function AddItemModal({ refreshItems }: AddItemModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();
  const {
    onOpen: openAddSupplierModal,
  } = useDisclosure();
  const [formData, setFormData] = useState({
    name: '',
    supplier_id: '',
    quantity: '',
    cost_price: '',
    selling_price: '',
    min_level: '',
    expiry_date: null as Date | null,
    category_name: '',
    warehouse_location: '',
    shelf_number: '',
    batch_number: '000',
  });
  const [categories, setCategories] = useState<any[]>([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const fetchSuppliers = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get(`${apiUrl}/suppliers`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });
      const fetchedSuppliers = response.data?.data?.Suppliers;
      if (Array.isArray(fetchedSuppliers)) {
        setSuppliers(fetchedSuppliers);
      } else {
        setSuppliers([]);
        toast({
          title: 'No Suppliers Found',
          description: 'There are currently no suppliers available.',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error Fetching Suppliers',
        description: 'Could not load supplier list.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setSuppliers([]);
    }
  }, [accessToken, tenant, apiUrl, toast]);


  useEffect(() => {
    if (!accessToken || !tenant) return;
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/categories`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        });
        const fetchedCategories = response.data?.data?.Categories;
        if (Array.isArray(fetchedCategories)) {
          setCategories(fetchedCategories);
        } else {
          setCategories([]);
          toast({
            title: 'No Categories Found',
            description: 'There are currently no categories available.',
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'Error Fetching Categories',
          description: 'Could not load category list.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setCategories([]);
      }
    };
    fetchCategories();
  }, [accessToken, tenant, apiUrl, toast]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const handleInputChange = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    // Validation and submit logic (same as in AddItemForm)
    if (!formData.name.trim()) {
      toast({
        title: 'Validation Error',
        description: 'Item Name is required.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (categories.length > 0 && !formData.category_name) {
      toast({
        title: 'Validation Error',
        description: 'Please select a category.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const expiryDateValue = formData.expiry_date || null;
      const singleItem = {
        name: formData.name,
        supplier_id: formData.supplier_id
          ? parseInt(formData.supplier_id, 10)
          : null,
        quantity: formData.quantity ? parseInt(formData.quantity, 10) : null,
        cost_price: formData.cost_price ? parseFloat(formData.cost_price) : null,
        selling_price: formData.selling_price ? parseFloat(formData.selling_price) : null,
        min_level: formData.min_level ? parseInt(formData.min_level, 10) : null,
        expiry_date: expiryDateValue,
        category_name: formData.category_name,
        batch_number: formData.batch_number,
      };
      const payload = { Items: [singleItem] };

      await axios.post(`${apiUrl}/inventory/items`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });

      toast({
        title: 'Item Added Successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Reset form data
      setFormData({
        name: '',
        supplier_id: '',
        quantity: '',
        cost_price: '',
        selling_price: '',
        min_level: '',
        expiry_date: null,
        category_name: '',
        warehouse_location: '',
        shelf_number: '',
        batch_number: '000',
      });
      refreshItems();
      onClose();
    } catch (error: any) {
      toast({
        title: 'Error Adding Item',
        description: error.response?.data?.message || 'Something went wrong.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        w={{ base: '100px', md: '150px' }}
        h="32px"
        onClick={onOpen}
        aria-label="Add Item"
      >
        Add New Item Type
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Card p="30px">
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                    <InputField
                      label="Item Name"
                      placeholder="e.g., Red Tattoo Ink"
                      value={formData.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange('name', e.target.value)
                      }
                      isRequired
                    />
                    {/* Category Selection */}
                    <SelectField
                      label="Category"
                      placeholder={
                        categories.length > 0 ? 'Select Category' : 'No categories available'
                      }
                      value={formData.category_name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange('category_name', e.target.value)
                      }
                      isDisabled={categories.length === 0}
                      isRequired={categories.length > 0}
                    >
                      {categories.length > 0 ? (
                        categories.map((cat) => (
                          <option key={cat.id} value={cat.name}>
                            {cat.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No categories available</option>
                      )}
                    </SelectField>
                    <InputField
                      label="Low Stock Threshold"
                      placeholder="e.g., 5"
                      value={formData.min_level}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange('min_level', e.target.value)
                      }
                      isRequired
                    />
                    {/* Supplier Selection */}
                    <SelectField
                      label="Supplier"
                      placeholder={
                        suppliers.length > 0 ? 'Select Supplier' : 'No suppliers available'
                      }
                      value={formData.supplier_id}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const val = e.target.value;
                        if (val === 'ADD_NEW_SUPPLIER') {
                          openAddSupplierModal();
                        } else {
                          handleInputChange('supplier_id', val);
                        }
                      }}
                      isDisabled={suppliers.length === 0}
                      isRequired={suppliers.length > 0}
                    >
                      {suppliers.length > 0 ? (
                        <>
                          {suppliers.map((sup: any) => (
                            <option key={sup.id} value={sup.id}>
                              {sup.name}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option value="">No suppliers available</option>
                      )}
                    </SelectField>
                    <InputField
                      label="Purchase Price"
                      placeholder="e.g., 4.50"
                      value={formData.cost_price}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange('cost_price', e.target.value)
                      }
                      isRequired
                    />
                    <InputField
                      label="Selling Price"
                      placeholder="e.g., 10.00"
                      value={formData.selling_price}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange('selling_price', e.target.value)
                      }
                      isRequired
                    />
                    <DateField
                      label="Expiry Date"
                      value={formData.expiry_date}
                      onChange={(date: Date | null) =>
                        handleInputChange('expiry_date', date ? date.toISOString() : '')
                      }
                      isRequired
                    />
                    <InputField
                      label="Batch Number"
                      placeholder="e.g., 001"
                      value={formData.batch_number}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange('batch_number', e.target.value)
                      }
                      isRequired
                    />
                  </SimpleGrid>
                </Stack>
              </Flex>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose} aria-label="Cancel">
              Cancel
            </Button>
            <Button colorScheme="brand" onClick={handleSubmit} aria-label="Submit New Item">
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
