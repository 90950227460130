// src/components/dataDisplay/AddSupplierForm.tsx

import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField'; // Ensure you have this component
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';

interface SupplierFormData {
  name: string;
  contact_email: string;
  contact_phone: string;
  address: string;
}

interface AddSupplierFormProps {
  refreshSuppliers: () => void;

}

export default function AddSupplierForm({ refreshSuppliers, }: AddSupplierFormProps) {
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // Modal control
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Form state
  const [formData, setFormData] = useState<SupplierFormData>({
    name: '',
    contact_email: '',
    contact_phone: '',
    address: '',
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Handle input changes
  const handleInputChange = (field: keyof SupplierFormData, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Basic validation
    if (!formData.name.trim()) {
      toast({
        title: 'Validation Error',
        description: 'Supplier Name is required.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Additional validations (e.g., email format) can be added here

    setIsSubmitting(true);
    try {
      const payload = {
        Suppliers: [
          {
            name: formData.name,
            contact_email: formData.contact_email,
            contact_phone: formData.contact_phone,
            address: formData.address,
          },
        ],
      };

      const response = await axios.post(`${apiUrl}/suppliers`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: { tenant },
      });

      if (response.status === 201 || response.status === 200 || response.status === 202) {
        toast({
          title: 'Supplier Added',
          description: 'The supplier has been added successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setFormData({
          name: '',
          contact_email: '',
          contact_phone: '',
          address: '',
        });
        refreshSuppliers(); // Refresh the suppliers table
        onClose(); // Close the modal after successful submission
      } else {
        throw new Error('Failed to add supplier.');
      }
    } catch (error: any) {
      toast({
        title: 'Error',
        description:
          error.response?.data?.message || 'An error occurred while adding the supplier.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle modal close to reset form
  const handleModalClose = () => {
    setFormData({
      name: '',
      contact_email: '',
      contact_phone: '',
      address: '',
    });
    onClose();
  };

  return (
    <>
      {/* Add Supplier Button */}
      <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        w={{ base: '100px', md: '110px' }}
        h="32px"
        onClick={onOpen}
        aria-label="Add Supplier"
      >
        Add Supplier
      </Button>

      {/* Add Supplier Modal */}
      <Modal isOpen={isOpen} onClose={handleModalClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Supplier</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="column" spacing="20px" mt="10px">
              <InputField
                label="Supplier Name"
                placeholder="Enter supplier name"
                value={formData.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('name', e.target.value)
                }
                isRequired
              />
              <InputField
                label="Contact Email"
                placeholder="Enter contact email"
                value={formData.contact_email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('contact_email', e.target.value)
                }
                type="email"
              />
              <InputField
                label="Contact Phone"
                placeholder="Enter contact phone"
                value={formData.contact_phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('contact_phone', e.target.value)
                }
                type="tel"
              />
              <InputField
                label="Address"
                placeholder="Enter address"
                value={formData.address}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('address', e.target.value)
                }
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={handleModalClose}
              aria-label="Cancel Adding Supplier"
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleSubmit}
              isLoading={isSubmitting}
              loadingText="Submitting"
              aria-label="Submit Supplier"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
