// src/components/forms/BusinessSettingsPage.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { 
  Button, 
  useColorModeValue, 
  Box, 
  Flex, 
  Tab, 
  TabList, 
  TabPanel, 
  TabPanels, 
  Tabs, 
  Text, 
  SimpleGrid, 
  Stack,
  useToast
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
import { Tenant } from 'components/types/tenants';  // Import the Tenant type

export function BusinessSettingsForm() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({ item: true });
  const [tabIndex, setTabIndex] = useState(0);

  const apiUrl = useApi();
  const accessToken = useToken();
  const tenantId = useTenant();
  const toast = useToast();

  const [formData, setFormData] = useState<Tenant>({
    id: 0,
    name: '',
    currency: '',
    timezone: '',
    instagram_profile: '',
    notification_email: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTenant = useCallback(async () => {
    if (!accessToken || !tenantId) return;
    try {
      const response = await axios.get<{ data: { Tenants: Tenant[] } }>(
        `${apiUrl}/tenants`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant: tenantId },
        }
      );
      const tenants = response.data.data.Tenants;
      if (tenants && tenants.length > 0) {
        setFormData(tenants[0]);  // Use the first tenant record
      }
      setError(null);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Error fetching tenant data.');
    } finally {
      setLoading(false);
    }
  }, [accessToken, tenantId, apiUrl]);

  useEffect(() => {
    fetchTenant();
  }, [fetchTenant]);

  const handleInputChange = (field: keyof Tenant, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!accessToken || !tenantId) return;
    try {
      await axios.put(
        `${apiUrl}/tenants`, 
        { Tenants: [formData]  }, 
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant: tenantId },
        }
      );
      toast({
        title: 'Settings updated successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (err: any) {
      toast({
        title: 'Error updating settings.',
        description: err.response?.data?.message || 'An unexpected error occurred.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Flex
      direction="column"
      minH="90vh"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Box
        h="45vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        index={tabIndex}
        onChange={(index) => {
          setTabIndex(index);
          setActiveBullets({ item: true });
        }}
        variant="unstyled"
        zIndex="0"
        mt={{ base: '80px', md: '10px' }}
        display="flex"
        flexDirection="column"
      >
        <TabList display="flex" alignItems="center" alignSelf="center" justifySelf="center">
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            w={{ sm: '120px', md: '230px', lg: '320px' }}
            onClick={() => setActiveBullets({ item: true })}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '300px' },
                height: '3px',
                bg: 'brand.400',
                left: { sm: '12px', md: '30px' },
                top: { sm: '6px', md: null },
                position: 'absolute',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor='white'
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color='white'
                fontWeight='bold'
                display={{ sm: 'none', md: 'block' }}
              >
                General
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel w={{ sm: '330px', md: '700px', lg: '850px' }} p="0px" mx="auto">
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Business Settings
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                    <InputField
                      mb="0px"
                      id="name"
                      placeholder="eg. My Awesome Studio."
                      label="Business Name"
                      value={formData.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('name', e.target.value)}
                    />
                    <InputField
                      mb="0px"
                      id="currency"
                      placeholder="e.g., USD"
                      label="Currency"
                      value={formData.currency}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('currency', e.target.value)}
                    />
                    <InputField
                      mb="0px"
                      id="timezone"
                      placeholder="e.g., EST"
                      label="Timezone"
                      value={formData.timezone}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('timezone', e.target.value)}
                    />
                    <InputField
                      mb="0px"
                      id="instagram_profile"
                      placeholder="Instagram profile link"
                      label="Instagram Profile"
                      value={formData.instagram_profile}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('instagram_profile', e.target.value)}
                    />
                    <InputField
                      mb="0px"
                      id="notification_email"
                      placeholder="mailbox@example.com"
                      label="Notification Email"
                      value={formData.notification_email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('notification_email', e.target.value)}
                    />
                  </SimpleGrid>
                </Stack>
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
