import React, { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useTenant } from 'components/hooks/useTenant';
import { useToken } from 'components/hooks/useToken';

interface CategoriesMenuProps {
  refetchData: () => void;
  searchValue: string;
}

export default function CategoriesMenu({ refetchData, searchValue }: CategoriesMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const apiUrl = useApi();
  const [multipleNames, setMultipleNames] = useState('');
  const token = useToken();
  const tenant = useTenant();
  const handleQuickAdd = async () => {
    if (!searchValue) {
      toast({
        title: 'No name found.',
        description: 'Please type something in the search bar to quick-add.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const requestBody = {
        Categories: [
          {
            name: searchValue,
          },
        ],
      };

      await axios.post(apiUrl+'/categories', requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tenant: tenant,
        },
      });

      toast({
        title: 'Category Added (Quick Add)',
        description: `Successfully added "${searchValue}"`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      refetchData();
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error creating category.',
        description: 'Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddMultiple = async () => {
    try {
      const namesArray = multipleNames
        .split('\n')
        .map((n) => n.trim())
        .filter((n) => n !== '');

      const payload = {
        Categories: namesArray.map((name) => ({ name })),
      };
      await axios.post(apiUrl+'/categories', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          tenant: tenant,
        },
      });

      toast({
        title: 'Categories Added',
        description: `Successfully added ${namesArray.length} categories.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setMultipleNames('');
      onClose();
      refetchData();
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error creating categories.',
        description: 'Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box ml={4}>
      {/* QUICK ADD */}
      <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        w={{ base: '100px', md: '110px' }}
        h="32px"
        onClick={handleQuickAdd}
      >
        Quick Add
      </Button>

      {/* ADD MULTIPLE */}
      <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        w={{ base: '120px', md: '130px' }}
        h="32px"
        ms="15px"
        onClick={onOpen}
      >
        Add Multiple
      </Button>

      {/* The Modal for "Add Multiple" */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add multiple categories</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Enter one category per line</FormLabel>
              <Textarea
                placeholder=""
                value={multipleNames}
                onChange={(e) => setMultipleNames(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="brand" onClick={handleAddMultiple}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
