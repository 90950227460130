import React, { ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  
    if (isLoading) {
      return <Loading message="Loading..." />;
    }
  
    // At this point, loading is finished. If still not authenticated, now redirect.
    if (!isAuthenticated) {
      loginWithRedirect();
      return null;
    }
  
    return <>{children}</>;
  };

export default ProtectedRoute;
