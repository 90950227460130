// src/views/admin/jobs/components/JobDetailsModal.tsx
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Spinner,
  Text,
  useToast,
  Input,
  Select,
  useDisclosure,
  Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
import { JobItem } from 'components/types/jobs';
import { Job } from 'components/types/jobs';

interface JobDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  job: Job | null;
  refreshJobs: () => void;
}

interface ItemData {
  id: number;
  name: string;
  quantity: number;
}

export default function JobDetailsModal({
  isOpen,
  onClose,
  job,
  refreshJobs,
}: JobDetailsModalProps) {
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();

  const [jobItems, setJobItems] = useState<JobItem[]>([]);
  const [editableJobItems, setEditableJobItems] = useState<JobItem[]>([]);
  const [itemsMap, setItemsMap] = useState<{ [key: number]: ItemData }>({});
  const [loading, setLoading] = useState(false);

  const [editableName, setEditableName] = useState('');
  const [editableStatus, setEditableStatus] = useState('');
  const [editableDate, setEditableDate] = useState<string>('');

  const { isOpen: isDeleteOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();

  // New state to manage edit mode
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Store original values to reset on cancel
  const [originalName, setOriginalName] = useState<string>('');
  const [originalStatus, setOriginalStatus] = useState<string>('');
  const [originalDate, setOriginalDate] = useState<string>('');
  const [originalJobItems, setOriginalJobItems] = useState<JobItem[]>([]);

  // Initialize editable fields when a job is selected
  useEffect(() => {
    if (job) {
      setEditableName(job.name);
      setEditableStatus(job.status);
      setEditableDate(job.date ? new Date(job.date).toISOString().split('T')[0] : '');
      setOriginalName(job.name);
      setOriginalStatus(job.status);
      setOriginalDate(job.date ? new Date(job.date).toISOString().split('T')[0] : '');
    }
  }, [job]);

  // Initialize editableJobItems when jobItems are fetched
  useEffect(() => {
    if (jobItems.length > 0) {
      setEditableJobItems(jobItems);
      setOriginalJobItems(jobItems);
    }
  }, [jobItems]);

  // Fetch job items and items map when modal opens
  useEffect(() => {
    if (!isOpen || !job) return;

    const fetchJobItems = async () => {
      setLoading(true);
      try {
        const resp = await axios.get<{ data: { JobItems: JobItem[] } }>(
          `${apiUrl}/job_items`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { tenant, job_id: job.id },
          }
        );
        setJobItems(resp.data.data.JobItems || []);
      } catch (err: any) {
        toast({
          title: 'Error fetching job items',
          description: err.response?.data?.message || 'Could not load job items.',
          status: 'error',
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    };

    const fetchItemsMap = async () => {
      try {
        const resp = await axios.get<{ data: { Items: ItemData[] } }>(
          `${apiUrl}/inventory/items`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { tenant },
          }
        );
        const list = resp.data.data.Items || [];
        const map: { [key: number]: ItemData } = {};
        list.forEach((item) => {
          map[item.id] = item;
        });
        setItemsMap(map);
      } catch (err) {
        console.error('Error fetching items map:', err);
      }
    };

    fetchJobItems();
    fetchItemsMap();
  }, [isOpen, job, apiUrl, accessToken, tenant, toast]);

  // Handle deletion of the job
  const handleDelete = async () => {
    if (!job) return;

    try {
      await axios.delete(`${apiUrl}/jobs`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant, job_id: job.id },
      });

      toast({
        title: 'Job deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      refreshJobs(); // Refresh the jobs list in parent
      onClose(); // Close the modal
    } catch (err: any) {
      toast({
        title: 'Error deleting job',
        description: err.response?.data?.message || 'Failed to delete job.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle saving changes: delete and recreate the job
  const handleSaveChanges = async () => {
    if (!job) return;
  
    // Validate at least one job item exists (optional)
    if (editableJobItems.length === 0) {
      toast({
        title: 'Validation Error',
        description: 'A job must have at least one item.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      // Build a new job payload using updated fields and items
      const newJobPayload = {
        tenant_id: job.tenant_id,
        name: editableName,
        status: editableStatus,
        // Convert editableDate string to an ISO8601 formatted string
        date: editableDate ? new Date(editableDate).toISOString() : null,
        items: editableJobItems.map((item) => ({
          item_id: item.item_id,
          quantity: item.quantity,
        })),
      };
  
      // Delete the existing job
      await axios.delete(`${apiUrl}/jobs`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant, job_id: job.id },
      });
  
      // Create a new job with the updated details
      await axios.post(
        `${apiUrl}/jobs`,
        { jobs: [newJobPayload] },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
  
      toast({
        title: 'Job updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
  
      refreshJobs();
      onClose();
      setIsEditing(false); // Reset editing state
    } catch (err: any) {
      toast({
        title: 'Error updating job',
        description: err.response?.data?.message || 'Failed to update job.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleMarkAsComplete = async () => {
    if (!job) return;
  
    // Fetch current inventory items to get accurate quantities
    let inventoryItems: (ItemData)[] = [];
    try {
      const resp = await axios.get<{ data: { Items: ItemData[] } }>(
        `${apiUrl}/inventory/items`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      inventoryItems = resp.data.data.Items || [];
    } catch (err) {
      toast({ title: "Error fetching inventory", status: "error" });
      return;
    }
  
    const inventoryMap: { [key: number]: ItemData } = {};
    inventoryItems.forEach(item => {
      inventoryMap[item.id] = item;
    });
  
    // Prepare updates for each job item
    const updates = jobItems.map(ji => {
      const existingItem = inventoryMap[ji.item_id];
      if (existingItem) {
        const newQty = existingItem.quantity - ji.quantity;
        return { Id: existingItem.id, Quantity: newQty };
      }
      return null;
    }).filter((x): x is { Id: number; Quantity: number } => x !== null);
  
    try {
      // Send updated quantities to the backend
      await axios.put(`${apiUrl}/inventory/itemsQuantity`, { items: updates }, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });
  
      // Update job status to "Complete"
      const updatedJob = { ...job, status: "Complete" };
      await axios.put(`${apiUrl}/jobs`, { jobs: [updatedJob] }, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });
  
      toast({ title: "Job marked as complete.", status: "success" });
      refreshJobs();
      onClose();
    } catch (err: any) {
      toast({
        title: "Error marking job as complete.",
        description: err.response?.data?.message || '',
        status: "error",
      });
    }
  };
  


  // Handle entering edit mode
  const handleEdit = () => {
    setIsEditing(true);
  };

  // Handle canceling edit mode
  const handleCancelEdit = () => {
    // Revert to original values
    setEditableName(originalName);
    setEditableStatus(originalStatus);
    setEditableDate(originalDate);
    setEditableJobItems(originalJobItems);
    setIsEditing(false);
  };

  const handleModalClose = () => {
    // Reset edit mode and original values when modal closes
    setIsEditing(false);
    setEditableName(originalName);
    setEditableStatus(originalStatus);
    setEditableDate(originalDate);
    setEditableJobItems(originalJobItems);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{job ? `Job Details (ID: ${job.id})` : 'Job Details'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {/* Editable Job Fields */}
                {isEditing ? (
                  <Box mb="4">
                    <Text mb="1">Job Name:</Text>
                    <Input
                      value={editableName}
                      onChange={(e) => setEditableName(e.target.value)}
                      mb="3"
                    />

                    <Text mb="1">Status:</Text>
                    <Input
                      value={editableStatus}
                      onChange={(e) => setEditableStatus(e.target.value)}
                      mb="3"
                    />

                    <Text mb="1">Date:</Text>
                    <Input
                      type="date"
                      value={editableDate}
                      onChange={(e) => setEditableDate(e.target.value)}
                      mb="3"
                    />
                  </Box>
                ) : (
                  <Box mb="4">
                    <Text mb="1">Job Name:</Text>
                    <Text mb="3">{editableName}</Text>

                    <Text mb="1">Status:</Text>
                    <Text mb="3">{editableStatus}</Text>

                    <Text mb="1">Date:</Text>
                    <Text mb="3">
                      {editableDate ? new Date(editableDate).toLocaleDateString() : ''}
                    </Text>
                  </Box>
                )}

                {/* Editable Job Items Section */}
                {isEditing ? (
                  <Box mb="4">
                    <Text fontSize="lg" fontWeight="bold" mb="2">
                      Job Items
                    </Text>
                    {editableJobItems.map((ji, index) => (
                      <Box
                        key={index}
                        borderWidth="1px"
                        borderRadius="md"
                        p="2"
                        mb="4"
                      >
                        <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                          <Box flex="1">
                            <Text mb="1">Item:</Text>
                            <Select
                              value={ji.item_id ? ji.item_id.toString() : ''}
                              onChange={(e) => {
                                const newItemId = parseInt(e.target.value, 10);
                                setEditableJobItems((prev) => {
                                  const updated = [...prev];
                                  updated[index] = { ...updated[index], item_id: newItemId };
                                  return updated;
                                });
                              }}
                            >
                              <option value="" disabled>
                                Select an item
                              </option>
                              {Object.values(itemsMap).map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Select>
                          </Box>

                          <Box flex="1">
                            <Text mb="1">Quantity:</Text>
                            <Input
                              type="number"
                              min="1"
                              value={ji.quantity}
                              onChange={(e) => {
                                const newQty = parseInt(e.target.value, 10) || 1;
                                setEditableJobItems((prev) => {
                                  const updated = [...prev];
                                  updated[index] = { ...updated[index], quantity: newQty };
                                  return updated;
                                });
                              }}
                            />
                          </Box>

                          <Box display="flex" alignItems="flex-end">
                            <Button
                              colorScheme="red"
                              size="sm"
                              onClick={() => {
                                setEditableJobItems((prev) => prev.filter((_, i) => i !== index));
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        </Stack>
                      </Box>
                    ))}
                    <Button
                      onClick={() => {
                        // Add a new blank job item row with default values
                        const firstItemId = Object.values(itemsMap)[0]?.id || 0;
                        setEditableJobItems((prev) => [
                          ...prev,
                          { item_id: firstItemId, quantity: 1 } as JobItem,
                        ]);
                      }}
                    >
                      Add Another Item
                    </Button>
                  </Box>
                ) : (
                  <>
                    {/* Read-Only Job Items Section */}
                    <Box mb="4">
                      <Text fontSize="lg" fontWeight="bold" mb="2">
                        Job Items
                      </Text>
                      {jobItems.length === 0 ? (
                        <Text>No items found for this job.</Text>
                      ) : (
                        jobItems.map((ji, index) => {
                          const baseItem = itemsMap[ji.item_id];
                          const itemName = baseItem ? baseItem.name : `Item #${ji.item_id}`;
                          return (
                            <Box
                              key={index}
                              borderWidth="1px"
                              borderRadius="md"
                              p="2"
                              mb="4"
                            >
                              <Text fontWeight="bold">{itemName}</Text>
                              <Text>Quantity: {ji.quantity}</Text>
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  </>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {isEditing ? (
                <>
                <Button colorScheme="green" mr={3} onClick={handleSaveChanges}>
                    Save Changes
                </Button>
                <Button variant="ghost" onClick={handleCancelEdit}>
                    Cancel
                </Button>
                </>
            ) : (
                <>
                <Button colorScheme="blue" mr={3} onClick={handleEdit}>
                    Edit
                </Button>
                <Button colorScheme="red" mr={3} onClick={openDeleteModal}>
                    Delete Job
                </Button>
                {job && job.status.toLowerCase() !== 'complete' && (
                    <Button colorScheme="teal" mr={3} onClick={handleMarkAsComplete}>
                    Mark as Complete
                    </Button>
                )}
                <Button onClick={handleModalClose}>Close</Button>
                </>
            )}
            </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation Modal for Deletion */}
      <Modal isOpen={isDeleteOpen} onClose={closeDeleteModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete "{job?.name}"?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={closeDeleteModal}
              aria-label="Cancel Deletion"
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={async () => {
                await handleDelete();
                closeDeleteModal();
              }}
              aria-label="Confirm Deletion"
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
