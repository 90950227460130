// CoreConceptsPage.tsx
import React from 'react';
import { Box, Heading, Text, useColorModeValue, List, ListItem, Image, Stack } from '@chakra-ui/react';
import CoreConceptsImage from 'assets/img/docs/coreConcepts.png';
const CoreConceptsPage: React.FC = () => {
  const headingColor = useColorModeValue('gray.700', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box>
      <Heading as="h3" size="lg" color={headingColor} mb={4}>
        Core Concepts
      </Heading>
      <Stack alignContent={'center'} alignItems={'center'}>
        <Image
          src={CoreConceptsImage}
          alt="Core Concepts"
        />
      </Stack>
      <Text color={textColor} fontSize="md" mb={4}>
      We have four main concepts that are central to BeeStocked:
      </Text>

         <List>
        <Text color={textColor} fontSize="md" mb={4}>
            <ListItem color={textColor}>
             <strong>Inventory</strong> - The core of BeeStocked. These are the things that your business owns, for both professional use and for sale to customers.
            </ListItem>
          </Text>
          <Text color={textColor} fontSize="md" mb={4}>
            <ListItem color={textColor}>
              <strong>Jobs</strong> - The things you do that consume inventory items.
            </ListItem>
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
            <ListItem color={textColor}>
              <strong>Orders</strong> - These are the orders you have put in to your suppliers, to get more of the things you own when you run low or just fancy more of, we're not judging.
            </ListItem>
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
            <ListItem color={textColor}>
              <strong>Stock</strong> - Stock is the inventory items that you offer to sale to customers specifically.
            </ListItem>
            </Text>
         </List>
         <Text color={textColor} fontSize="md" mb={4}>
          By effectively using each of these concepts, you will get the most value out of your time using BeeStocked.
      </Text>
    </Box>
  );
};

export default CoreConceptsPage;