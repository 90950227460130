import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutPage: React.FC = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    // Perform the logout as soon as this component mounts
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return null; // or a loading state
};

export default LogoutPage;