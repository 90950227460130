import React, { useEffect, useState, useCallback } from 'react';
import {
  Flex,
  Text,
  MenuList,
  useColorModeValue,
  Box,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import axios from 'axios';

// Icons
import {
  MdInfoOutline,
  MdOutlineWarning,
  MdDateRange,
  MdMoveToInbox,
  MdDone,
} from 'react-icons/md';

// Custom Notification component
import Notification from 'components/dataDisplay/Notification';

interface NotificationsPanelProps {
  accessToken?: string;
  tenant?: number | string;
  apiUrl?: string;
}

interface NotificationData {
  id: number;
  message: string;
  type: string;
  category: string;        // e.g. "expiring item", "low stock item", etc.
  created_at: string;  // date/time string
  updated_at: string;
  is_read: boolean;
}

export default function NotificationsPanel({
  accessToken,
  tenant,
  apiUrl,
}: NotificationsPanelProps) {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(true);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  const fetchNotifications = useCallback(async () => {
    if (!accessToken || !tenant || !apiUrl) return;
    setLoading(true);
    try {
      const res = await axios.get<{ data: { Notifications: NotificationData[] } }>(
        `${apiUrl}/notifications`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      setNotifications(res.data.data.Notifications || []);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  }, [accessToken, tenant, apiUrl]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const handleToggleUnread = () => {
    setShowUnreadOnly((prev) => !prev);
  };


  const handleMarkAllRead = async () => {
    if (!accessToken || !tenant || !apiUrl) return;
    try {
      await axios.get(`${apiUrl}/notifications/markAllAsRead`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });
      fetchNotifications();
    } catch (error) {
      console.error('Error marking all as read:', error);
    }
  };


  const handleMarkAsRead = async (notificationId: number) => {
    if (!accessToken || !tenant || !apiUrl) return;
    try {
      await axios.get(`${apiUrl}/notifications/markAsRead`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant, notification_id: notificationId },
      });
      fetchNotifications();
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };


  function getIconForCategory(type?: string) {
    if (!type) return <MdInfoOutline />;
    switch (type.toLowerCase()) {
      case 'expiring item':
        return <MdDateRange />;
      case 'low stock item':
        return <MdMoveToInbox />;
      case 'warning':
        return <MdOutlineWarning />;
      default:
        return <MdInfoOutline />;
    }
  }

  const displayedNotifications = showUnreadOnly
    ? notifications.filter((n) => !n.is_read)
    : notifications;

  return (
    <MenuList
      boxShadow={shadow}
      p="20px"
      borderRadius="20px"
      bg={menuBg}
      border="none"
      mt="22px"
      minW={{ base: 'unset', md: '400px', xl: '450px' }}
      maxW={{ base: '360px', md: 'unset' }}
      maxH="400px"
      overflowY="auto"
      textAlign="left"
    >

      <Flex w="100%" mb="20px" align="center" textAlign="left">
        <Text fontSize="md" fontWeight="600" color={textColor}>
          Notifications
        </Text>

        <Text
          fontSize="sm"
          fontWeight="500"
          color={textColorBrand}
          ms="auto"
          cursor="pointer"
          onClick={handleToggleUnread}
          mr="16px"
        >
          {showUnreadOnly ? 'Show All' : 'Show Unread Only'}
        </Text>

        <Text
          fontSize="sm"
          fontWeight="500"
          color={textColorBrand}
          cursor="pointer"
          onClick={handleMarkAllRead}
        >
          Mark all read
        </Text>
      </Flex>

      {loading ? (
        <Flex justify="center" p="10px">
          <Spinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" textAlign="left">
          {displayedNotifications.map((notif) => {
            const createdDate = new Date(notif.created_at).toLocaleString();

            return (
              <Flex
                key={notif.id}
                align="center"
                justify="space-between"
                px="0"
                py="8px"
                borderRadius="8px"
                mb="10px"
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
              >
                <Notification
                  date={createdDate}
                  icon={getIconForCategory(notif.category)}
                  message={notif.message}
                  category={notif.category}
                  is_read={notif.is_read}
                />
                {!notif.is_read && (
                  <IconButton
                    aria-label="Mark as read"
                    icon={<MdDone />}
                    size="sm"
                    variant="ghost"
                    onClick={() => handleMarkAsRead(notif.id)}
                  />
                )}
              </Flex>
            );
          })}

          {displayedNotifications.length === 0 && !loading && (
            <Box textAlign="center" p="10px">
              <Text fontSize="sm" color={textColor}>
                No notifications
              </Text>
            </Box>
          )}
        </Flex>
      )}
    </MenuList>
  );
}
