import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';


const root = ReactDOM.createRoot(document.getElementById('root'));
const audience = "https://beeinked.co/beestocked"
root.render(
  <Auth0Provider
    domain="beeinked.eu.auth0.com"
    clientId="4qzbUPbUHljaJY7ZzqHTR7A76jACmqPO"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: audience
    }}
    >
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Auth0Provider>,
);
