import React, { useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  useDisclosure,
  useToast,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton,
  Button
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  ColumnFiltersState,
  getFilteredRowModel
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import CategoriesMenu from './categoriesMenu';
import TableCRUDActions from 'components/actions/TableCRUDActions';
import { MdEdit, MdDelete } from 'react-icons/md';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { Category } from 'components/types/categories';
import EditCategoryModal from './editCategoryModal';
import axios from 'axios';
interface CategoriesTableProps {
  tableData: Category[];
  refetchData: () => void;
  accessToken: string;
  tenant: string | number;
  apiUrl: string;
}

export default function CategoriesTable({ tableData, refetchData, accessToken, tenant, apiUrl }: CategoriesTableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<Category | null>(null);
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [categoryToDelete, setCategoryToDelete] = useState<Category | null>(null);
  const [deletingCategoryId, setDeletingCategoryId] = useState<number | null>(null);
  const toast = useToast();
  
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  
  
  const columnHelper = createColumnHelper<Category>();
  const data = React.useMemo(() => tableData, [tableData]);
  const columns = React.useMemo(() => [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          CATEGORY
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <Flex align="center" paddingX={'10px'} margin={'auto'} >
            <Icon
              as={MdEdit}
              w="20px"
              h="20px"
              cursor="pointer"
              color={textColor}
              onClick={() => handleEdit(info.row.original)}
              aria-label={`Edit ${info.row.original.name}`}
            />
          <Icon
            as={MdDelete}
            w="20px"
            h="20px"
            cursor="pointer"
            color="red.500"
            onClick={() => handleDeleteClick(info.row.original)}
            style={{ marginLeft: '10px' }}
            aria-label={`Delete ${info.row.original.name}`}
          />
        </Flex>
      ),
    }),
  ], [columnHelper, textColor]);

  function handleEdit(row: { name: string }) {
    const category = tableData.find(cat => cat.name === row.name);
    if (category) {
      setCategoryToEdit(category);
      setEditModalOpen(true);
    }
  }

  const handleDeleteClick = (category: Category) => {
    setCategoryToDelete(category);
    onDeleteOpen();
  };

  const performDelete = async () => {
    if (!categoryToDelete) return;
  
    setDeletingCategoryId(categoryToDelete.id);
    try {
      const response = await axios.delete(`${apiUrl}/categories`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: { tenant },
        data: {
          Categories: [
            {
              id: categoryToDelete.id,
              name: categoryToDelete.name,
              // Optionally include other fields if needed
            },
          ],
        },
      });
  
      if (response.status === 200 || response.status === 204) {
        toast({
          title: 'Category deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onDeleteClose();
        setCategoryToDelete(null);
        refetchData(); // Refresh categories data
      } else {
        throw new Error('Deletion failed');
      }
    } catch (error: any) {
      // Extract error message from response or fallback to error.message
      const errMsg =
      error.response?.data?.error?.message || 
      error.response?.data?.message || 
      error.message || 
      '';
  
      if (errMsg.includes("cannot delete category")) {
        toast({
          title: 'Deletion Error',
          description: 'Cannot delete category because it has assigned items.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error(error);
        toast({
          title: 'Failed to delete category',
          description: errMsg || 'An error occurred while deleting the category.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setDeletingCategoryId(null);
    }
  };

  // Create the React Table
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      columnFilters,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <Card w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Categories
        </Text>
      </Flex>

      {/* Search Bar & Buttons */}
      <Flex px="25px" py="10px" mb="0px" mr={'20px'} justifyContent="left" align="center">
        <DebouncedInput
          value={globalFilter}
          onChange={(value) => setGlobalFilter(String(value))}
          placeholder="Search categories..."
          style={{ fontSize: '1.1rem', border: '0' }}
        />

        {/* Pass refetchData + globalFilter to the menu */}
        <CategoriesMenu
          refetchData={refetchData}
          searchValue={globalFilter}
        />
      </Flex>

      <Box w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }} padding={'25px'}>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Flex
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getRowModel().rows.length === 0 ? (
              <Tr>
              <Td colSpan={columns.length} textAlign="center" py={4}>
                <Text fontSize="md" color="gray.500">
                  No categories to display. Please add some :)
                </Text>
              </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '14px' }}
                      borderColor="transparent"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
        {isEditModalOpen && categoryToEdit && (
        <EditCategoryModal
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          category={categoryToEdit}
          accessToken={accessToken}
          tenant={tenant}
          refreshCategories={refetchData}
        />
      )}
      
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Are you sure you want to delete "{categoryToDelete?.name}"?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onDeleteClose} aria-label="Cancel Deletion">
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={performDelete}
            isLoading={deletingCategoryId === categoryToDelete?.id}
            loadingText="Deleting"
            aria-label="Confirm Deletion"
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

      </Box>
    </Card>
  );
}

// Same DebouncedInput from your code
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      h="44px"
      w={{ lg: '390px' }}
      borderRadius="16px"
    />
  );
}
