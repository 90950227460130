// src/components/dataDisplay/StockMovementsTable.tsx
import { Box, SimpleGrid, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import StockMovement from "components/dataDisplay/StockMovement";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import React from "react";

export type Movement = {
  date: string;
  sum: string;
  name: string;
  reason: string;
};

interface StockMovementsTableProps {
  tableData: Movement[];
}

export default function StockMovementsTable({ tableData }: StockMovementsTableProps) {
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize] = React.useState(10); 
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [filteredMovements, setFilteredMovements] = React.useState<Movement[]>(tableData);

  React.useEffect(() => {
    const filtered = tableData.filter((movement) =>
      movement.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
      movement.reason.toLowerCase().includes(globalFilter.toLowerCase())
    );
    setFilteredMovements(filtered);
    setPageIndex(0); // Reset pageIndex when filter changes
  }, [globalFilter, tableData]);

  const start = pageIndex * pageSize;
  const end = start + pageSize;
  const pageData = filteredMovements.slice(start, end);
  const pageCount = Math.ceil(filteredMovements.length / pageSize);
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box>
      <Flex mb="40px" justify="space-between" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Recent Stock Movement
        </Text>
      </Flex>
      <Box mb="20px">
        <SearchBar
          value={globalFilter}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value)}
          h="44px"
          w={{ lg: "390px" }}
          borderRadius="16px"
          placeholder="Search by name or reason..."
        />
      </Box>

      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        {pageData.map((movement, index) => (
          <StockMovement
            key={index}
            date={movement.date}
            sum={movement.sum}
            name={movement.name}
            reason={movement.reason}
          />
        ))}
      </SimpleGrid>

      <Flex justify="space-between" align="center">
        <Text>
          Showing {filteredMovements.length === 0 ? 0 : start + 1} to{" "}
          {end > filteredMovements.length ? filteredMovements.length : end} of{" "}
          {filteredMovements.length} entries
        </Text>
        <Flex gap="10px">
          <Button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>
            Prev
          </Button>
          <Button
            onClick={() => setPageIndex(pageIndex + 1)}
            disabled={pageIndex >= pageCount - 1 || pageCount === 0}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
