import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormControl,
    FormLabel,
    useToast,
  } from '@chakra-ui/react';
  import React, { useState, useEffect } from 'react';
  import axios from 'axios';
  import { useApi } from 'components/hooks/useApi';
  
  interface Category {
    id: number;
    name: string;
  }
  
  interface EditCategoryModalProps {
    isOpen: boolean;
    onClose: () => void;
    category: Category | null;
    accessToken: string;
    tenant: string | number;
    refreshCategories: () => void;
  }
  
  const EditCategoryModal: React.FC<EditCategoryModalProps> = ({
    isOpen,
    onClose,
    category,
    accessToken,
    tenant,
    refreshCategories,
  }) => {
    const toast = useToast();
    const apiUrl = useApi();
    const [name, setName] = useState<string>('');
  
    useEffect(() => {
      if (category) {
        setName(category.name);
      }
    }, [category]);
  
    const handleSave = async () => {
      if (!category) return;
      try {
        const payload = {
          Categories: [
            {
              id: category.id,
              name: name,
            },
          ],
        };
  
        const response = await axios.put(`${apiUrl}/categories`, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          params: { tenant },
        });
  
        if (response.status === 200 || response.status === 204) {
          toast({
            title: 'Category updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onClose();
          refreshCategories();
        } else {
          throw new Error('Update failed');
        }
      } catch (error: any) {
        console.error(error);
        toast({
          title: 'Failed to update category',
          description: error.response?.data?.message || 'An error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Category Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  
  export default EditCategoryModal;