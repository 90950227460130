// Chakra imports
import { Flex, Text, useColorModeValue, Badge } from '@chakra-ui/react';
import IconBoxLink from 'components/icons/IconBox';
import { MdDrafts } from 'react-icons/md';


export default function IncomingOrder(props: { date: string; status: string; name: string; [x: string]: any, orderDocumentLink: string }) {
	const { date, status, name, orderDocumentLink, ...rest } = props;
	const iconBoxBg = useColorModeValue('secondaryGray.300', 'grey');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Flex justifyContent='center' alignItems='center' w='100%' {...rest}>
			<IconBoxLink h='42px' w='42px' bg={iconBoxBg} me='20px' icon={<MdDrafts />} link={orderDocumentLink} />
			<Flex direction='column' align='start' me='auto'>
				<Text color={textColor} fontSize='md' me='6px' fontWeight='700'>
					{name} 
				</Text>
				<Text color='secondaryGray.600' fontSize='sm' fontWeight='500'>
					Arrival: {date}
				</Text>
			</Flex>
			<Badge
				ms='auto'
				colorScheme={status === 'due'
					 ? 'yellow'
					 : status === 'Pending'
					 ? 'yellow'
					 : status === 'Received'
					 ? 'green'
					 : status === 'Overdue'
					 ? 'red'
					 : status === 'Cancelled'
					 ? 'grey'
					 : 'green'
					}
				color={status === 'due'
					? 'yellow.500'
					: status === 'Pending'
					? 'yellow.500'
					: status === 'Received'
					? 'green.500'
					: status === 'Overdue'
					? 'red.500'
					: status === 'Cancelled'
					? 'grey.500'
					: 'green.500'}
				fontSize='sm'>
				{status}
			</Badge>
			
		</Flex>
	);
}
