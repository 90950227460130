// src/components/dataDisplay/SupplierTable.tsx

import React, { useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Stack,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { MdEdit, MdDelete, MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { useApi } from 'components/hooks/useApi';
import axios from 'axios';
import EditSupplierModal from 'views/admin/orders/suppliers/components/editSupplierModal';
import { Order } from 'components/types/orders';
// Define Supplier type
interface Supplier {
  id: number;
  name: string;
  contact_email: string;
  contact_phone: string;
  address: string;
}

// Define component props
interface SupplierTableProps {
  tableData: Supplier[];
  tableName: string;
  refreshSuppliers: () => void;
  accessToken: string;
  tenant: string | number;
  fakeSave?: boolean;
}

const columnHelper = createColumnHelper<Supplier>();

export default function SupplierTable({
  tableData,
  tableName,
  refreshSuppliers,
  accessToken,
  tenant,
  fakeSave,
}: SupplierTableProps): JSX.Element {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pageIndex] = useState(0);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const toast = useToast();
  const apiUrl = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [supplierToDelete, setSupplierToDelete] = useState<Supplier | null>(null);
  const [deletingSupplierId, setDeletingSupplierId] = useState<number | null>(null);

  // Edit State
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [editValues, setEditValues] = useState<Partial<Supplier>>({});
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [supplierToEdit, setSupplierToEdit] = useState<Supplier | null>(null);
  // Initialize data
  const data = useMemo(() => tableData, [tableData]);

  // Define columns without 'editValues' in dependencies
  const columns =  [
      // Edit Column
      columnHelper.display({
        id: 'edit',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            EDIT
          </Text>
        ),
        cell: (info) => {
          const rowData = info.row.original;
          const isEditing = rowData.id === editingRowId;

          if (isEditing) {
            return (
              <Stack direction="row" spacing={2}>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => handleSave(rowData.id)}
                  aria-label="Save Changes"
                >
                  Save
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={handleCancel}
                  aria-label="Cancel Editing"
                >
                  Cancel
                </Button>
              </Stack>
            );
          } else {
            return (
              <Icon
                as={MdEdit}
                w="20px"
                h="20px"
                cursor="pointer"
                color={textColor}
                onClick={() => handleEditClick(rowData)}
                aria-label={`Edit ${rowData.name}`}
              />
            );
          }
        },
      }),
      // Name Column
      columnHelper.accessor('name', {
        id: 'name',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            NAME
          </Text>
        ),
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              value={editValues.name ?? ''}
              onChange={(e) => handleChange(e, 'name')}
              aria-label="Edit Supplier Name"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {info.getValue()}
            </Text>
          );
        },
      }),
      // Contact Email Column
      columnHelper.accessor('contact_email', {
        id: 'contact_email',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            EMAIL
          </Text>
        ),
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              type="email"
              value={editValues.contact_email ?? ''}
              onChange={(e) => handleChange(e, 'contact_email')}
              aria-label="Edit Contact Email"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {info.getValue()}
            </Text>
          );
        },
      }),
      // Contact Phone Column
      columnHelper.accessor('contact_phone', {
        id: 'contact_phone',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            PHONE
          </Text>
        ),
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              type="tel"
              value={editValues.contact_phone ?? ''}
              onChange={(e) => handleChange(e, 'contact_phone')}
              aria-label="Edit Contact Phone"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {info.getValue()}
            </Text>
          );
        },
      }),
      // Address Column
      columnHelper.accessor('address', {
        id: 'address',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            ADDRESS
          </Text>
        ),
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              value={editValues.address ?? ''}
              onChange={(e) => handleChange(e, 'address')}
              aria-label="Edit Address"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {info.getValue()}
            </Text>
          );
        },
      }),
      // Delete Column
      columnHelper.display({
        id: 'delete',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            DELETE
          </Text>
        ),
        cell: (info) => (
          <Icon
            as={MdDelete}
            w="20px"
            h="20px"
            cursor="pointer"
            color="red.500"
            onClick={() => handleDeleteClick(info.row.original)}
            aria-label={`Delete ${info.row.original.name}`}
          />
        ),
      }),
    ]

  // Initialize table
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      pagination: { pageIndex, pageSize: 10 },
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleEditClick = (supplier: Supplier) => {
    setSupplierToEdit(supplier);
    setEditModalOpen(true);
  };
  // Handle Input Change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof Supplier) => {
    const value = e.target.value;
    setEditValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle Cancel Editing
  const handleCancel = () => {
    setEditingRowId(null);
    setEditValues({});
  };

  // Handle Save Changes
  const handleSave = async (id: number) => {
    try {
      if (fakeSave) {
        // FAKE SAVE CHANGES
        toast({
          title: 'Example Update: no changes made',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        setEditingRowId(null);
        setEditValues({});
        refreshSuppliers();
        return;
      }
      const updatedSupplier = {
        Suppliers: [
          {
            id: id,
            name: editValues.name ?? '',
            contact_email: editValues.contact_email ?? '',
            contact_phone: editValues.contact_phone ?? '',
            address: editValues.address ?? '',
          },
        ],
      };

      const response = await axios.put(`${apiUrl}/suppliers`, updatedSupplier, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Ensure correct header if needed
          'Content-Type': 'application/json',
        },
        params: {
          tenant: tenant,
        },
      });

      if (response.status === 200 || response.status === 204) {
        toast({
          title: 'Supplier updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setEditingRowId(null);
        setEditValues({});
        refreshSuppliers(); // Refresh data after update
      } else {
        throw new Error('Update failed');
      }
    } catch (error: any) {
      console.error(error);
      toast({
        title: 'Failed to update supplier',
        description:
          error.response?.data?.message || 'An error occurred while updating the supplier.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle Delete Click
  const handleDeleteClick = async (supplier: Supplier) => {
    if (fakeSave) {
      // FAKE DELETE
      toast({
        title: 'Example Delete: no changes made',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      refreshSuppliers();
      return;
    }
    try {
      // Fetch orders referencing this supplier
      const response = await axios.get<{ data: { Orders: Order[] } }>(`${apiUrl}/ordersBySupplier`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant, supplier_id: supplier.id }, // Assuming your API can filter by supplier_id
      });
  
      const ordersUsingSupplier = response.data.data.Orders;
      if (ordersUsingSupplier && ordersUsingSupplier.length > 0) {
        toast({
          title: 'Cannot delete supplier',
          description: 'This supplier is being used by existing orders.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;  // Prevent deletion
      }
  
      setSupplierToDelete(supplier);
      onOpen();
    } catch (error: any) {
      console.error(error);
      toast({
        title: 'Error checking supplier usage',
        description: 'Could not verify if supplier is in use.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Perform Delete
  const performDelete = async () => {
    if (!supplierToDelete) return;
    if (fakeSave) {
      toast({
        title: 'Example Delete: no changes made',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      setSupplierToDelete(null);
      onClose();
      return;
    }
    setDeletingSupplierId(supplierToDelete.id);
    try {
      const response = await axios.delete(`${apiUrl}/suppliers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: {
          tenant: tenant,
        },
        data: {
          Suppliers: [
            {
              id: supplierToDelete.id,
              name: supplierToDelete.name,
              contact_email: supplierToDelete.contact_email,
              contact_phone: supplierToDelete.contact_phone,
              address: supplierToDelete.address,
            },
          ],
        },
      });

      if (response.status === 200 || response.status === 204) {
        toast({
          title: 'Supplier deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setSupplierToDelete(null);
        onClose();
        refreshSuppliers();
      } else {
        throw new Error('Deletion failed');
      }
    } catch (error: any) {
      console.error(error);
      toast({
        title: 'Failed to delete supplier',
        description:
          error.response?.data?.message || 'An error occurred while deleting the supplier.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDeletingSupplierId(null);
    }
  };

  // Pagination Controls
  const createPages = (count: number) => {
    let pages = [];
    for (let i = 1; i <= count; i++) {
      pages.push(i);
    }
    return pages;
  };

  const pageCount = table.getPageCount();

  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX="auto">
      {/* Header */}
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          {tableName}
        </Text>
        <Menu />
      </Flex>

      {/* Search Input */}
      <Flex px="25px" py="10px" justifyContent="left" align="center">
        <Input
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search suppliers..."
          style={{
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
          aria-label="Search Suppliers"
        />
      </Flex>

      {/* Table */}
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={columns.length} textAlign="center">
                  <Text fontSize="md" color="gray.500">
                    No suppliers to display.
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      {/* Pagination Controls */}
      <Flex w="100%" justify="space-between" px="20px" py="10px">
        <Text fontSize="sm" color="gray.500" fontWeight="normal" mb={{ sm: '24px', md: '0px' }}>
          Showing {pageIndex * 10 + 1} to {Math.min((pageIndex + 1) * 10, data.length)} of {data.length} entries
        </Text>

        <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
          <Button
            variant="no-effects"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            transition="all .5s ease"
            w="40px"
            h="40px"
            borderRadius="50%"
            bg="transparent"
            border="1px solid"
            borderColor={useColorModeValue('gray.200', 'white')}
            display={table.getCanPreviousPage() ? 'flex' : 'none'}
            _hover={{
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
            aria-label="Previous Page"
          >
            <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
          </Button>
          {createPages(pageCount).map((pageNumber) => (
            <Button
              key={pageNumber}
              variant="no-effects"
              transition="all .5s ease"
              onClick={() => table.setPageIndex(pageNumber - 1)}
              w="40px"
              h="40px"
              borderRadius="50%"
              bg={pageNumber === table.getState().pagination.pageIndex + 1 ? 'brand.500' : 'transparent'}
              border={
                pageNumber === table.getState().pagination.pageIndex + 1
                  ? 'none'
                  : '1px solid lightgray'
              }
              _hover={
                pageNumber === table.getState().pagination.pageIndex + 1
                  ? { opacity: '0.7' }
                  : { bg: 'whiteAlpha.100' }
              }
            >
              <Text
                fontSize="sm"
                color={pageNumber === table.getState().pagination.pageIndex + 1 ? '#fff' : textColor}
              >
                {pageNumber}
              </Text>
            </Button>
          ))}
          <Button
            variant="no-effects"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            transition="all .5s ease"
            w="40px"
            h="40px"
            borderRadius="50%"
            bg="transparent"
            border="1px solid"
            borderColor={useColorModeValue('gray.200', 'white')}
            display={table.getCanNextPage() ? 'flex' : 'none'}
            _hover={{
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
            aria-label="Next Page"
          >
            <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
          </Button>
        </Stack>
      </Flex>

      {/* Confirmation Modal for Deletion */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete "{supplierToDelete?.name}"?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose} aria-label="Cancel Deletion">
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={performDelete}
              isLoading={deletingSupplierId === supplierToDelete?.id}
              loadingText="Deleting"
              aria-label="Confirm Deletion"
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <EditSupplierModal
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        supplier={supplierToEdit}
        accessToken={accessToken}
        tenant={tenant}
        refreshSuppliers={refreshSuppliers}
        fakeSave={fakeSave}
      />
    </Card>
  );
}
