// src/components/forms/AddTenant.tsx

import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi'; 

interface AddTenantProps {
  accessToken: string;
  tenant: string | number;
  onSuccess?: () => void; 
}

const AddTenant: React.FC<AddTenantProps> = ({ accessToken, tenant, onSuccess }) => {
  const apiUrl = useApi();
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: '',
    currency: '',
    timezone: '',
    instagram_profile: '',
    notification_email: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Validate form data
  const validateForm = (): boolean => {
    const { name, currency, timezone, notification_email } = formData;
    if (!name.trim()) {
      toast({
        title: 'Validation Error',
        description: 'Tenant name is required.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    if (!currency.trim()) {
      toast({
        title: 'Validation Error',
        description: 'Currency is required.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    if (!timezone.trim()) {
      toast({
        title: 'Validation Error',
        description: 'Timezone is required.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    if (!notification_email.trim()) {
      toast({
        title: 'Validation Error',
        description: 'Notification email is required.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    // Basic email format check
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(notification_email)) {
      toast({
        title: 'Validation Error',
        description: 'Please enter a valid email address.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const payload = {
        name: formData.name,
        currency: formData.currency,
        timezone: formData.timezone,
        instagram_profile: formData.instagram_profile,
        notification_email: formData.notification_email,
      };

      const response = await axios.post(`${apiUrl}/tenants`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: {
          tenant: tenant,
        },
      });

      if (response.status === 201 || response.status === 200 || response.status == 202 ) {
        toast({
          title: 'Tenant Added',
          description: 'The tenant has been added successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Reset form
        setFormData({
          name: '',
          currency: '',
          timezone: '',
          instagram_profile: '',
          notification_email: '',
        });

        // Callback after success (e.g., refresh TenantTable)
        if (onSuccess) {
          onSuccess();
        }
      } else {
        throw new Error('Failed to add tenant.');
      }
    } catch (error: any) {
      console.error('Error adding tenant:', error);
      toast({
        title: 'Submission Error',
        description:
          error.response?.data?.message || 'An error occurred while adding the tenant.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const cardBg = useColorModeValue('white', 'gray.700');

  return (
    <Flex direction="column" align="center" p={6}>
      <Box
        bg={cardBg}
        p={6}
        rounded="md"
        shadow="md"
        width={{ base: '100%', md: '500px' }}
      >
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            {/* Tenant Name */}
            <FormControl id="name" isRequired>
              <FormLabel>Tenant Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter tenant name"
              />
            </FormControl>

            {/* Currency */}
            <FormControl id="currency" isRequired>
              <FormLabel>Currency</FormLabel>
              <Input
                type="text"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
                placeholder="Enter currency (e.g., USD, EUR)"
              />
            </FormControl>

            {/* Timezone */}
            <FormControl id="timezone" isRequired>
              <FormLabel>Timezone</FormLabel>
              <Input
                type="text"
                name="timezone"
                value={formData.timezone}
                onChange={handleChange}
                placeholder="Enter timezone (e.g., UTC, GMT)"
              />
            </FormControl>

            {/* Instagram Profile */}
            <FormControl id="instagram_profile">
              <FormLabel>Instagram Profile</FormLabel>
              <Input
                type="text"
                name="instagram_profile"
                value={formData.instagram_profile}
                onChange={handleChange}
                placeholder="Enter Instagram profile URL"
              />
            </FormControl>

            {/* Notification Email */}
            <FormControl id="notification_email" isRequired>
              <FormLabel>Notification Email</FormLabel>
              <Input
                type="email"
                name="notification_email"
                value={formData.notification_email}
                onChange={handleChange}
                placeholder="Enter notification email"
              />
            </FormControl>

            {/* Submit Button */}
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isSubmitting}
              loadingText="Submitting"
            >
              Add Tenant
            </Button>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
};

export default AddTenant;
