import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormControl,
    FormLabel,
  } from '@chakra-ui/react';
  import React, { useState, useEffect } from 'react';
  import { useToast } from '@chakra-ui/react';
  import axios from 'axios';
  import { useApi } from 'components/hooks/useApi';
  import { Supplier } from 'components/types/suppliers';

  interface EditSupplierModalProps {
    isOpen: boolean;
    onClose: () => void;
    supplier: Supplier | null;
    accessToken: string;
    tenant: string | number;
    refreshSuppliers: () => void;
    fakeSave?: boolean;
  }
  
  const EditSupplierModal: React.FC<EditSupplierModalProps> = ({
    isOpen,
    onClose,
    supplier,
    accessToken,
    tenant,
    refreshSuppliers,
    fakeSave,
  }) => {
    const toast = useToast();
    const apiUrl = useApi();
    const [formData, setFormData] = useState<Supplier>({
      id: 0,
      name: '',
      contact_email: '',
      contact_phone: '',
      address: '',
    });
  
    useEffect(() => {
      if (supplier) {
        setFormData(supplier);
      }
    }, [supplier]);
  
    const handleChange = (field: keyof Supplier, value: string) => {
      setFormData(prev => ({ ...prev, [field]: value }));
    };
  
    const handleSave = async () => {
      if (fakeSave) {
        // FAKE SAVE CHANGES
        toast({
          title: 'Example Update: no changes made',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        onClose();
        refreshSuppliers();
        return;
      }
      try {
        const payload = {
          Suppliers: [
            {
              id: formData.id,
              name: formData.name,
              contact_email: formData.contact_email,
              contact_phone: formData.contact_phone,
              address: formData.address,
            },
          ],
        };
  
        const response = await axios.put(`${apiUrl}/suppliers`, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          params: { tenant },
        });
  
        if (response.status === 200 || response.status === 204) {
          toast({
            title: 'Supplier updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onClose();
          refreshSuppliers();
        } else {
          throw new Error('Update failed');
        }
      } catch (error: any) {
        console.error(error);
        toast({
          title: 'Failed to update supplier',
          description:
            error.response?.data?.message || 'An error occurred while updating the supplier.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Supplier</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={3}>
              <FormLabel>Name</FormLabel>
              <Input
                value={formData.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Contact Email</FormLabel>
              <Input
                type="email"
                value={formData.contact_email}
                onChange={(e) => handleChange('contact_email', e.target.value)}
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Contact Phone</FormLabel>
              <Input
                type="tel"
                value={formData.contact_phone}
                onChange={(e) => handleChange('contact_phone', e.target.value)}
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Address</FormLabel>
              <Input
                value={formData.address}
                onChange={(e) => handleChange('address', e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  
  export default EditSupplierModal;