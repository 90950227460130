import { Box } from "@chakra-ui/react";
import { BusinessSettingsForm } from "./components/businessSettingsForm";

export default function BusinessSettingsPage() {
    // Chakra Color Mode
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <BusinessSettingsForm />
        </Box>
    );
};