// IntroductionPage.tsx
import React from 'react';
import { Box, Heading, Text, useColorModeValue } from '@chakra-ui/react';

const IntroductionPage: React.FC = () => {
  const headingColor = useColorModeValue('gray.700', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box>
      <Heading as="h3" size="lg" color={headingColor} mb={4}>
        Introduction
      </Heading>
      <Text color={textColor} fontSize="md" mb={4}>
        Welcome to BeeStocked, we're super happy to have you here! 
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        BeeStocked is a powerful and intuitive business management application designed to streamline 
        your day-to-day operations. Whether you're managing inventory, processing orders, overseeing jobs, 
        or coordinating with suppliers, BeeStocked provides the tools you need to enhance efficiency, accuracy, 
        and productivity in your business processes.
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
      At its core, BeeStocked is built to simplify complex business tasks through a user-friendly interface and robust functionality. 
      Ensuring that keeping on top of your stock and inventory is both effortless and enjoyable.
      </Text>
      <Text color={textColor} fontSize="lg" mb={4}>
        Feedback and Support  
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        Your experience with BeeStocked is the most important thing to us. If you encounter any issues, have any suggestions, or just 
        want to get in touch with us, please feel free to reach out through our support channels or DM us on instragram @beeinkedSoftware.
        We're absolutely committed to continuously improving and enhancing BeeStocked to better serve your needs now and in the future.
        </Text>
    </Box>
  );
};

export default IntroductionPage;