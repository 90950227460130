// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import IconBox from 'components/icons/IconBox';

export default function Notification(props: {
	date: string;
	icon: JSX.Element;
	message: string;
	category: string;
	is_read: boolean;
	[x: string]: any;
  }) {
	const { date, icon, message, category, is_read, ...rest } = props;
  
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const iconBoxBg = useColorModeValue('secondaryGray.300', 'navy.700');
	const bgColorUnread = useColorModeValue('gray.50', 'whiteAlpha.100');
	const containerBg = is_read ? 'transparent' : bgColorUnread;
	return (
	  <Flex
		w="100%"
		alignItems="center"
		bg={containerBg}
		{...rest}
	  >
		<IconBox
		  h="42px"
		  w="42px"
		  bg={iconBoxBg}
		  me="20px"
		  icon={icon}
		/>

		<Flex direction="column" align="flex-start" justify="center">

		  <Text
			color={textColor}
			fontSize="md"
			fontWeight="700"
			mb="2px"
		  >
			{category}
		  </Text>
  
		  <Text
			color={textColor}
			fontSize="sm"
			fontWeight="500"
			mb="2px"
		  >
			{message}
		  </Text>
  
		  <Text
			color="secondaryGray.600"
			fontSize="xs"
			fontWeight="500"
		  >
			{date}
		  </Text>
		</Flex>
	  </Flex>
	);
  }
