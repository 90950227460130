// src/views/admin/jobs/JobsHistory.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { SimpleGrid, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
import { Job } from 'components/types/jobs';
import JobHistoryTable from './components/jobHistoryTable';
import AddJobModal from './components/addJobModal';
import JobHistoryHeader from './components/jobsHistoryHeader';

export default function JobsHistory() {
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchJobs = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get<{ data: { Jobs: Job[] } }>(
        `${apiUrl}/jobs`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      console.log(response.data.data.Jobs)
      setJobs(response.data.data.Jobs || []);
      setError(null);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Error fetching jobs');
      toast({
        title: 'Error',
        description: err.response?.data?.message || 'Error fetching jobs',
        status: 'error',
        duration: 4000,
      });
    } finally {
      setLoading(false);
    }
  }, [apiUrl, accessToken, tenant, toast]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  if (loading) {
    return <p>Loading jobs...</p>;
  }
  if (error) {
    return <p style={{ color: 'red' }}>Error: {error}</p>;
  }
  return (
  <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <JobHistoryHeader /> 
        <AddJobModal refreshJobs={fetchJobs} />
        <JobHistoryTable jobs={jobs} refreshJobs={fetchJobs} />
    </SimpleGrid>
  );
}
