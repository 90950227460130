import { Order } from "components/types/orders";
import { Supplier } from "components/types/suppliers";

export const mockSuppliers: Supplier[] = [
    {
      id: 1,
      name: 'Honey Suppliers Co.',
      contact_email: 'contact@honeysuppliers.com',
      contact_phone: '+1 (555) 123-4567',
      address: '123 Bee Lane, Sweetville, TX 75001',
    },
    {
      id: 2,
      name: 'Propolis Distributors',
      contact_email: 'info@propolisdist.com',
      contact_phone: '+1 (555) 234-5678',
      address: '456 Nectar Street, Bloomtown, CA 90001',
    },
    {
      id: 3,
      name: 'Beeswax Suppliers Ltd.',
      contact_email: 'sales@beeswaxsuppliers.com',
      contact_phone: '+1 (555) 345-6789',
      address: '789 Hive Avenue, Comb City, NY 10001',
    },
    {
      id: 4,
      name: 'Royal Jelly Enterprises',
      contact_email: 'support@royaljelly.com',
      contact_phone: '+1 (555) 456-7890',
      address: '321 Queen Bee Road, Royalville, FL 32004',
    },
    {
      id: 5,
      name: 'Pollen Partners Inc.',
      contact_email: 'partners@pollenpartners.com',
      contact_phone: '+1 (555) 567-8901',
      address: '654 Flower Blvd, Pollentown, IL 60007',
    },
    {
      id: 6,
      name: 'BeeCare Supplies',
      contact_email: 'service@beecaresupplies.com',
      contact_phone: '+1 (555) 678-9012',
      address: '987 Apiary Drive, Beehive City, WA 98001',
    },
  ];

export const mockOrders: Order[] = [
    {
        id: 1,
        order_date: '2023-03-01',
        due_date: '2025-03-15',
        status: 'Received',
        supplier_name: 'Honey Suppliers Co.',
        order_document_link: 'https://example.com/orders/1/document.pdf',
        supplier_id: 1,
        items: [
            {
                item_id: 101,
                item_quantity: 50,
                item_cost: 3.5,
                created_at: '2023-03-01',
                order_id: 1,
            },
            {
                item_id: 102,
                item_quantity: 30,
                item_cost: 2.99,
                created_at: '2023-03-01',
                order_id: 1,
            },
        ],
    },
    {
        id: 2,
        order_date: '2026-02-20',
        due_date: '2025-03-05',
        status: 'Shipped',
        supplier_name: 'Propolis Distributors',
        order_document_link: 'https://example.com/orders/2/document.pdf',
        supplier_id: 2,
        items: [
            {
                item_id: 103,
                item_quantity: 20,
                item_cost: 1.5,
                created_at: '2023-02-20',
                order_id: 2,
            },
        ],
    },
    {
        id: 3,
        order_date: '2023-01-15',
        due_date: '2025-02-01',
        status: 'Completed',
        supplier_name: 'Beeswax Suppliers Ltd.',
        order_document_link: 'https://example.com/orders/3/document.pdf',
        supplier_id: 3,
        items: [
            {
                item_id: 104,
                item_quantity: 100,
                item_cost: 0.99,
                created_at: '2023-01-15',
                order_id: 3,
            },
            {
                item_id: 105,
                item_quantity: 60,
                item_cost: 2.5,
                created_at: '2023-01-15',
                order_id: 3,
            },
            {
                item_id: 106,
                item_quantity: 40,
                item_cost: 1.75,
                created_at: '2023-01-15',
                order_id: 3,
            },
        ],
    },
    {
        id: 4,
        order_date: '2023-04-10',
        due_date: '2025-04-25',
        status: 'Cancelled',
        supplier_name: 'Royal Jelly Suppliers',
        order_document_link: 'https://example.com/orders/4/document.pdf',
        supplier_id: 4,
        items: [
            {
                item_id: 107,
                item_quantity: 25,
                item_cost: 4.99,
                created_at: '2023-04-10',
                order_id: 4,
            },
        ],
    },
    {
        id: 5,
        order_date: '2024-05-05',
        due_date: '2026-05-20',
        status: 'Received',
        supplier_name: 'Pollen Partners',
        order_document_link: 'https://example.com/orders/5/document.pdf',
        supplier_id: 5,
        items: [
            {
                item_id: 108,
                item_quantity: 80,
                item_cost: 3.25,
                created_at: '2023-05-05',
                order_id: 5,
            },
            {
                item_id: 109,
                item_quantity: 45,
                item_cost: 2.75,
                created_at: '2023-05-05',
                order_id: 5,
            },
        ],
    },
    {
        id: 6,
        order_date: '2024-06-01',
        due_date: '2023-06-15',
        status: 'Shipped',
        supplier_name: 'BeeCare Supplies',
        order_document_link: 'https://example.com/orders/6/document.pdf',
        supplier_id: 6,
        items: [
            {
                item_id: 110,
                item_quantity: 70,
                item_cost: 5.0,
                created_at: '2023-06-01',
                order_id: 6,
            },
            {
                item_id: 111,
                item_quantity: 35,
                item_cost: 3.75,
                created_at: '2023-06-01',
                order_id: 6,
            },
        ],
    },
];