import { SimpleGrid } from "@chakra-ui/react";
import ItemTable from "components/dataDisplay/ItemTable";
import StockHeader from "./components/stockHeader";
import { Item, ItemsResponse } from "components/types/items";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useToken } from "components/hooks/useToken";
import { useTenant } from "components/hooks/useTenant";
import { useApi } from "components/hooks/useApi";
import Loading from "components/Loading";
import AddStockMovementForm from "./components/createStockMovementForm";
export default function StockLevels() {
	const [tableData, setTableData] = useState<Item[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	const tenant = useTenant();
	const accessToken = useToken();
	const apiUrl = useApi();

	const fetchData = useCallback(async (): Promise<void> => {
		if (!accessToken || !tenant) return; // Ensure required data exists
	
		try {
			  const response = await axios.get<ItemsResponse>(
				apiUrl+"/inventory/stock",
				{
				  headers: {
					Authorization: `Bearer ${accessToken}`,
				  },
				  params: {
					tenant: tenant,
				  }
				}
		  );
		  setTableData(response.data.data.Items);
		  setError(null);
		} catch (err: unknown) {
		  if (axios.isAxiosError(err)) {
			setError(err.response?.data?.message || "An error occurred while fetching data");
		  } else if (err instanceof Error) {
			setError(err.message);
		  } else {
			setError("An unknown error occurred");
		  }
		} finally {
		  setLoading(false);
		}
	  }, [accessToken, tenant, apiUrl]);
  
	useEffect(() => {
		fetchData();
	}, [fetchData]);


  if (loading) return <Loading message="Loading stock levels..." />;
  if (error) return <p>Error: {error}</p>;
    return (
            <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
                <StockHeader />
				<AddStockMovementForm refreshStockMovements={fetchData} />
                <ItemTable 
					tableData={tableData} 
					tableName="Stock"
					accessToken={accessToken} 
        			tenant={tenant} 
					refreshItems={fetchData}
					/>
            </SimpleGrid>
    );
}