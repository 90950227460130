import { SimpleGrid, Stack } from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import OrderHistoryTable from "./components/orderHistoryTable";
import OrderHistoryHeader from "./components/orderHistoryHeader";
import AddOrderModal from "./components/addOrderModal";
import { useApi } from "components/hooks/useApi";
import { useToken } from "components/hooks/useToken";
import { useTenant } from "components/hooks/useTenant";
import axios from "axios";
import { Order } from "components/types/orders";
import { Supplier } from "components/types/suppliers";

export default function OrdersHistory() {
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();

  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const fetchOrders = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get<{ data: { Orders: Order[] } }>(
        `${apiUrl}/orders`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      setOrders(response.data.data.Orders || []);
      setError(null);
    } catch (err: any) {
      setError(err.response?.data?.message || "Error fetching orders");
    } finally {
      setLoading(false);
    }
  }, [accessToken, tenant, apiUrl]);

  const fetchSuppliers = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get<{ data: { Suppliers: Supplier[] } }>(
        `${apiUrl}/suppliers`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      setSuppliers(response.data.data.Suppliers || []);
    } catch (err) {
      console.warn('Error fetching suppliers.');
    }
  }, [accessToken, tenant, apiUrl]);

  useEffect(() => {
    fetchOrders();
    fetchSuppliers();
  }, [fetchOrders, fetchSuppliers]);

  return (
    <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
      <Stack>
      <OrderHistoryHeader />
      <AddOrderModal refreshOrders={fetchOrders} />
      <OrderHistoryTable orders={orders} suppliers={suppliers}/>
      </Stack>
      </SimpleGrid>
  );
}