import React, { useState, useEffect, useCallback } from 'react';
import ReactSelect from 'react-select';
import {
  Button,
  Box,
  Stack,
  Select,
  IconButton,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import InputField from 'components/fields/InputField';
import DayDateField from 'components/fields/DayDateField';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
import AddItemModal from 'views/admin/inventory/allitems/components/addItemModal';
import AddSupplierForm from '../../suppliers/components/addSupplierForm';

// =============== INTERFACES ===============
interface OrderItemRow {
  item_id: string;
  item_quantity: string;
  item_cost: string;
}

interface Supplier {
  id: number;
  name: string;
}

interface ItemData {
  id: number;
  name: string;
  cost_price: number;
  // If your backend returns batch_number or other fields, that’s okay—
  // we'll just ignore them for collapsing.
}

interface AddOrderModalProps {
  refreshOrders: () => void;
}

// =============== COLLAPSE LOGIC ===============
// Helper to "collapse" multiple items that share the same name.
// (Adjust if you want to group by category, supplier, etc.)
function collapseItems(rawItems: ItemData[]): ItemData[] {
  const map = new Map<string, ItemData>();

  for (const it of rawItems) {
    // We unify by item.name only:
    if (!map.has(it.name)) {
      map.set(it.name, { ...it }); 
      // If you want to do something like summing cost_price or picking the cheapest,
      // you can do it here instead of simply storing the first.
    }
    // else we skip additional duplicates
  }

  // Return the collapsed array
  return Array.from(map.values());
}

// =============== COMPONENT ===============
export default function AddOrderModal({ refreshOrders }: AddOrderModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();
  const toast = useToast();

  // Order-level fields
  const [supplierId, setSupplierId] = useState('');
  const [orderDate, setOrderDate] = useState<Date | null>(new Date());
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [orderValue, setOrderValue] = useState('');
  const [status, setStatus] = useState('Pending');

  // Order items state
  const [orderItems, setOrderItems] = useState<OrderItemRow[]>([
    { item_id: '', item_quantity: '', item_cost: '' },
  ]);

  // Suppliers and Items data
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [items, setItems] = useState<ItemData[]>([]);
  const [loadingItems, setLoadingItems] = useState(true);
  const [itemsError, setItemsError] = useState<string | null>(null);

  // For "Add New Item" modal
  const {
    isOpen: isItemModalOpen,
    onOpen: openItemModal,
    onClose: closeItemModal,
  } = useDisclosure();

  // =============== FETCH SUPPLIERS ===============
  const fetchSuppliers = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      const response = await axios.get<{ data: { Suppliers: Supplier[] } }>(
        `${apiUrl}/suppliers`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      const fetchedSuppliers = response.data.data.Suppliers;
      if (Array.isArray(fetchedSuppliers)) {
        setSuppliers(fetchedSuppliers);
      } else {
        setSuppliers([]);
        toast({
          title: 'No Suppliers Found',
          description: 'There are currently no suppliers available.',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      toast({
        title: 'Error Fetching Suppliers',
        description: err.response?.data?.message || 'Could not load supplier list.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setSuppliers([]);
    }
  }, [accessToken, tenant, apiUrl, toast]);

  // =============== CALCULATE ORDER VALUE ===============
  useEffect(() => {
    const total = orderItems.reduce((sum, item) => {
      const quantity = parseFloat(item.item_quantity) || 0;
      const cost = parseFloat(item.item_cost) || 0;
      return sum + quantity * cost;
    }, 0);
    setOrderValue(total.toFixed(2)); // Update with two decimal places
  }, [orderItems]);

  // =============== FETCH ITEMS (then collapse by name) ===============
  const fetchItems = useCallback(async () => {
    if (!accessToken || !tenant) return;
    try {
      setLoadingItems(true);
      const response = await axios.get<{
        data: { Items: ItemData[] };
      }>(`${apiUrl}/inventory/items`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });

      const rawItems = response.data.data.Items;
      // "Collapse" duplicates that differ only by batch
      const collapsed = collapseItems(rawItems);
      setItems(collapsed);
      setItemsError(null);
    } catch (err) {
      setItemsError('Error fetching items.');
    } finally {
      setLoadingItems(false);
    }
  }, [accessToken, tenant, apiUrl]);

  // =============== ON MOUNT, FETCH SUPPLIERS & ITEMS ===============
  useEffect(() => {
    fetchSuppliers();
    fetchItems();
  }, [fetchSuppliers, fetchItems]);

  // =============== HANDLERS FOR ORDER ITEMS ===============
  const handleOrderItemChange = (index: number, field: keyof OrderItemRow, value: string) => {
    setOrderItems((prev) => {
      const newItems = [...prev];
      newItems[index] = { ...newItems[index], [field]: value };
      return newItems;
    });
  };

  const addOrderItemRow = () => {
    setOrderItems((prev) => [...prev, { item_id: '', item_quantity: '', item_cost: '' }]);
  };

  const removeOrderItemRow = (index: number) => {
    setOrderItems((prev) => prev.filter((_, i) => i !== index));
  };

  // =============== SUBMIT ORDER ===============
  const handleSubmit = async () => {
    // Validate required fields
    if (!supplierId || !orderDate || !dueDate || !status) {
      toast({
        title: 'Validation Error',
        description: 'Supplier, Order Date, Due Date, and Status are required.',
        status: 'warning',
      });
      return;
    }
    for (const item of orderItems) {
      if (!item.item_id || !item.item_quantity) {
        toast({
          title: 'Validation Error',
          description: 'Each order item must have an item and quantity.',
          status: 'warning',
        });
        return;
      }
    }

    // Build payload
    const orderPayload = {
      Supplier_id: parseInt(supplierId, 10),
      Order_date: orderDate,
      Due_date: dueDate,
      Order_value: parseFloat(orderValue) || 0,
      Status: status,
      Items: orderItems.map((item) => ({
        Item_id: parseInt(item.item_id, 10),
        Item_quantity: parseInt(item.item_quantity, 10),
        Item_cost: parseFloat(item.item_cost) || 0,
      })),
    };

    try {
      await axios.post(
        `${apiUrl}/orders`,
        { orders: [orderPayload] }, // <== Note: Use "orders" key if your Go struct expects json:"orders"
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      toast({
        title: 'Order Added Successfully!',
        status: 'success',
      });
      // Reset state
      setSupplierId('');
      setOrderDate(null);
      setDueDate(null);
      setOrderValue('');
      setStatus('Pending'); // or blank if you prefer
      setOrderItems([{ item_id: '', item_quantity: '', item_cost: '' }]);
      refreshOrders();
      onClose();
    } catch (error: any) {
      toast({
        title: 'Error Adding Order',
        description: error.response?.data?.message || 'An unexpected error occurred.',
        status: 'error',
      });
    }
  };

  return (
      <>
        <Button
        variant="brand"
        fontSize="sm"
        borderRadius="16px"
        h="32px"
        onClick={onOpen}
        aria-label="Add New Order"
        >
          Add New Order
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Order</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="20px">
                <Stack direction="row" spacing="10px">
                  <AddItemModal
                    refreshItems={fetchItems}
                    isOpen={isItemModalOpen}
                    onClose={closeItemModal}
                  />
                  <AddSupplierForm  refreshSuppliers={fetchSuppliers} />
                </Stack>

                {/* Order-level fields */}
                <Select
                  placeholder="Select Supplier"
                  value={supplierId}
                  onChange={(e) => setSupplierId(e.target.value)}
                  isRequired
                >
                  {suppliers.map((sup) => (
                    <option key={sup.id} value={sup.id}>
                      {sup.name}
                    </option>
                  ))}
                </Select>
                <DayDateField
                  label="Order Date"
                  value={orderDate}
                  onChange={(date) => setOrderDate(date)}
                  isRequired
                />
                <DayDateField
                  label="Due Date"
                  value={dueDate}
                  onChange={(date) => setDueDate(date)}
                  isRequired
                />
                <InputField label="Order Value" placeholder="0.00" value={orderValue} isReadOnly />
                <InputField
                  label="Status"
                  placeholder="e.g., pending, completed"
                  value={status}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setStatus(e.target.value)
                  }
                  isRequired
                />

                {/* Expandable Section for Order Items */}
                <Box borderWidth="1px" borderRadius="md" p="4">
                  <Stack spacing="4">
                    {orderItems.map((item, index) => (
                      <Stack
                        key={index}
                        spacing="3"
                        borderWidth="1px"
                        p="4"
                        borderRadius="md"
                      >
                        <ReactSelect
                          placeholder="Select Item"
                          // Build options from 'items' (collapsed)
                          options={items.map((it) => ({
                            value: it.id.toString(),
                            label: it.name,
                            cost_price: it.cost_price,
                          }))}
                          onChange={(selectedOption) => {
                            if (!selectedOption) return;
                            // 'new' logic is optional. If you want "Add New Item" in the dropdown, do it.
                            if (selectedOption.value === 'new') {
                              openItemModal();
                              return;
                            }
                            handleOrderItemChange(index, 'item_id', selectedOption.value);
                            if (selectedOption.cost_price !== undefined) {
                              handleOrderItemChange(
                                index,
                                'item_cost',
                                selectedOption.cost_price.toString()
                              );
                            }
                          }}
                          // Set the dropdown value
                          value={
                            items.find(
                              (it) => it.id.toString() === orderItems[index].item_id
                            )
                              ? {
                                  value: orderItems[index].item_id,
                                  label: items.find(
                                    (it) => it.id.toString() === orderItems[index].item_id
                                  )?.name,
                                  cost_price: items.find(
                                    (it) => it.id.toString() === orderItems[index].item_id
                                  )?.cost_price,
                                }
                              : null
                          }
                          isClearable
                          isLoading={loadingItems}
                          noOptionsMessage={() =>
                            itemsError || 'No items available'
                          }
                        />

                        <InputField
                          label="Quantity"
                          placeholder="Enter quantity"
                          type="number"
                          value={item.item_quantity}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleOrderItemChange(index, 'item_quantity', e.target.value)
                          }
                          isRequired
                        />
                        <InputField
                          label="Cost"
                          placeholder="Enter cost"
                          type="number"
                          value={item.item_cost}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleOrderItemChange(index, 'item_cost', e.target.value)
                          }
                        />
                        {orderItems.length > 1 && (
                          <IconButton
                            aria-label="Remove Order Item"
                            icon={<CloseIcon />}
                            onClick={() => removeOrderItemRow(index)}
                            colorScheme="red"
                          />
                        )}
                      </Stack>
                    ))}
                    <Button onClick={addOrderItemRow} variant="outline">
                      Add Another Item to Order
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="brand" onClick={handleSubmit}>
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
  );
}
