// src/components/dataDisplay/TenantTable.tsx

import {
    Box,
    Flex,
    Icon,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Stack,
    Input,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    useDisclosure,
  } from '@chakra-ui/react';
  import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
  } from '@tanstack/react-table';
  import Card from 'components/card/Card';
  import Menu from 'components/menu/MainMenu';
  import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
  import axios from 'axios';
  import { MdEdit, MdDelete, MdChevronRight, MdChevronLeft } from 'react-icons/md';
  import { useApi } from 'components/hooks/useApi';
  
  interface RowObj {
    id: number;
    name: string;
    currency: string;
    timezone: string;
    instagram_profile: string;
    notification_email: string;
  }
  
  interface TenantTableProps {
    tableData: RowObj[];
    tableName: string;
    accessToken: string;
    tenant: string | number;
  }
  
  const columnHelper = createColumnHelper<RowObj>();
  
  export default function TenantTable({
    tableData,
    tableName,
    accessToken,
    tenant,     
  }: TenantTableProps): JSX.Element {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [data, setData] = useState<RowObj[]>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [pageIndex, ] = useState(0);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const toast = useToast();
    const apiUrl = useApi();
    const [editingRowId, setEditingRowId] = useState<number | null>(null);
    const [ , setEditValues] = useState<Partial<RowObj>>({});
    const editValuesRef = useRef<Partial<RowObj>>({}); // Initialize ref
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [itemToDelete, setItemToDelete] = useState<RowObj | null>(null);
    const [deletingItemId, setDeletingItemId] = useState<number | null>(null);
  
    // Update data only when not editing
    useEffect(() => {
      if (editingRowId === null) { 
        if (Array.isArray(tableData)) {
          setData(tableData);
        } else {
          setData([]);
        }
      }
    }, [tableData, editingRowId]);
  
    // Stable handleChange using useCallback
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, field: keyof RowObj) => {
        const rawValue = e.target.value;
        setEditValues((prev) => {
          const updated = { ...prev, [field]: rawValue };
          editValuesRef.current = updated;
          return updated;
        });
      },
      []
    );
  
    const handleDeleteClick = (item: RowObj) => {
      setItemToDelete(item);
      onOpen();
    };
  
    const performDelete = async (item: RowObj): Promise<void> => {
      setDeletingItemId(item.id);
      try {
        if (!accessToken || !tenant) {
          toast({
            title: 'Authentication Error',
            description: 'Missing authentication credentials.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
  
        const payload = {
          tenants: [{ id: item.id }], // Adjusted key
        };
  
        const response = await axios.delete(`${apiUrl}/tenants`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          params: {
            tenant: tenant,
          },
          data: payload, 
        });
  
        if (response.status === 200 || response.status === 204) {
          setData((prevData) => prevData.filter((row) => row.id !== item.id));
  
          toast({
            title: 'Tenant deleted successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error('Deletion failed');
        }
      } catch (error: unknown) {
        console.error(error);
        toast({
          title: 'Deletion Failed',
          description:
            axios.isAxiosError(error) && error.response?.data?.message
              ? error.response.data.message
              : 'An error occurred while deleting the tenant.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setDeletingItemId(null);
        onClose();
        setItemToDelete(null);
      }
    };
  
    const handleEditClick = (row: RowObj) => {
      setEditingRowId(row.id);
      setEditValues({ ...row });
      editValuesRef.current = { ...row }; // Sync ref with state
    };
  
    const handleCancel = () => {
      setEditingRowId(null);
      setEditValues({});
      editValuesRef.current = {}; // Reset ref
    };
  
    const handleSave = async (id: number) => {
        try {
          if (!accessToken || !tenant) {
            toast({
              title: 'Authentication Error',
              description: 'Missing authentication credentials.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            return;
          }
      
          // Prepare the tenant data using the ref to ensure the latest values
          const tenantData = {
            id: id,
            name: editValuesRef.current.name || '',
            currency: editValuesRef.current.currency || '',
            timezone: editValuesRef.current.timezone || '',
            instagram_profile: editValuesRef.current.instagram_profile || '',
            notification_email: editValuesRef.current.notification_email || '',
          };
      
          const payload = {
            tenants: [tenantData],
          };
      
          const response = await axios.put(`${apiUrl}/tenants`, payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            params: {
              tenant: tenant,
            },
          });
      
          if (response.status === 200) {
            setData((prevData) =>
              prevData.map((item) =>
                item.id === id ? { ...item, ...tenantData } : item
              )
            );
      
            toast({
              title: 'Tenant updated successfully',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
      
            setEditingRowId(null);
            setEditValues({});
            editValuesRef.current = {}; // Reset the ref
          } else {
            throw new Error('Update failed');
          }
        } catch (error: unknown) {
          console.error(error);
          toast({
            title: 'Failed to update tenant',
            description:
              axios.isAxiosError(error) && error.response?.data?.message
                ? error.response.data.message
                : 'An error occurred while updating the tenant.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      };
      
    const columns = useMemo(
      () => [
        columnHelper.accessor('name', {
          id: 'name',
          header: 'Tenant Name',
          cell: (info) => {
            const rowId = info.row.original.id;
            const isEditing = rowId === editingRowId;
            return isEditing ? (
              <Input
                size="sm"
                type="text"
                value={editValuesRef.current.name || ''}
                onChange={(e) => handleChange(e, 'name')}
                aria-label="Edit Tenant Name"
              />
            ) : (
              <Text fontSize="sm" fontWeight="700" color={textColor}>
                {info.getValue()}
              </Text>
            );
          },
        }),
        columnHelper.accessor('currency', {
          id: 'currency',
          header: 'Currency',
          cell: (info) => {
            const rowId = info.row.original.id;
            const isEditing = rowId === editingRowId;
            return isEditing ? (
              <Input
                size="sm"
                type="text" // Corrected type
                value={editValuesRef.current.currency || ''}
                onChange={(e) => handleChange(e, 'currency')}
                aria-label="Edit Currency"
              />
            ) : (
              <Text fontSize="sm" fontWeight="700" color={textColor}>
                {info.getValue()}
              </Text>
            );
          },
        }),
        columnHelper.accessor('timezone', {
          id: 'timezone',
          header: 'Timezone',
          cell: (info) => {
            const rowId = info.row.original.id;
            const isEditing = rowId === editingRowId;
            return isEditing ? (
              <Input
                size="sm"
                type="text"
                value={editValuesRef.current.timezone || ''}
                onChange={(e) => handleChange(e, 'timezone')}
                aria-label="Edit Timezone"
              />
            ) : (
              <Text fontSize="sm" fontWeight="700" color={textColor}>
                {info.getValue()}
              </Text>
            );
          },
        }),
        columnHelper.accessor('instagram_profile', {
          id: 'instagram_profile',
          header: 'Instagram Profile',
          cell: (info) => {
            const rowId = info.row.original.id;
            const isEditing = rowId === editingRowId;
            return isEditing ? (
              <Input
                size="sm"
                type="text"
                value={editValuesRef.current.instagram_profile || ''}
                onChange={(e) => handleChange(e, 'instagram_profile')}
                aria-label="Edit Instagram Profile"
              />
            ) : (
              <Text fontSize="sm" fontWeight="700" color={textColor}>
                {info.getValue()}
              </Text>
            );
          },
        }),
        columnHelper.accessor('notification_email', {
          id: 'notification_email',
          header: 'Notification Email',
          cell: (info) => {
            const rowId = info.row.original.id;
            const isEditing = rowId === editingRowId;
            return isEditing ? (
              <Input
                size="sm"
                type="email"
                value={editValuesRef.current.notification_email || ''}
                onChange={(e) => handleChange(e, 'notification_email')}
                aria-label="Edit Notification Email"
              />
            ) : (
              <Text fontSize="sm" fontWeight="700" color={textColor}>
                {info.getValue()}
              </Text>
            );
          },
        }),
        // -----------
        // Edit Column
        // -----------
        columnHelper.display({
          id: 'edit',
          header: 'Edit',
          cell: (info) => {
            const rowData = info.row.original;
            const rowId = rowData.id;
            const isEditing = rowId === editingRowId;
  
            if (isEditing) {
              return (
                <Stack direction="row" spacing={2}>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => handleSave(rowId)}
                    aria-label="Save Changes"
                  >
                    Save
                  </Button>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={handleCancel}
                    aria-label="Cancel Editing"
                  >
                    Cancel
                  </Button>
                </Stack>
              );
            } else {
              return (
                <Icon
                  as={MdEdit}
                  w="20px"
                  h="20px"
                  cursor="pointer"
                  color={textColor}
                  onClick={() => handleEditClick(rowData)}
                  aria-label={`Edit ${rowData.name}`}
                />
              );
            }
          },
        }),
        // -----------
        // Delete Column
        // -----------
        columnHelper.display({
          id: 'delete',
          header: 'Delete',
          cell: (info) => {
            const rowData = info.row.original;
            return (
              <Icon
                as={MdDelete}
                w="20px"
                h="20px"
                cursor="pointer"
                color="red.500"
                onClick={() => handleDeleteClick(rowData)}
                aria-label={`Delete ${rowData.name}`}
              />
            );
          },
        }),
      ],
      [textColor, editingRowId]
    );
  
    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
        globalFilter,
        pagination: { pageIndex, pageSize: 10 },
      },
      onSortingChange: setSorting,
      onGlobalFilterChange: setGlobalFilter,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    });
  
    return (
      <Card flexDirection="column" w="100%" px="0px" overflowX="auto">
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {tableName}
          </Text>
          <Menu />
        </Flex>
  
        <Flex px="25px" py="10px" justifyContent="left" align="center">
          <Input
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            style={{
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
            aria-label="Search Tenants"
          />
        </Flex>
  
        <Box>
          <Table variant="simple" color="gray.500">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} borderColor={borderColor}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td key={cell.id} borderColor={borderColor}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={table.getAllLeafColumns().length} textAlign="center">
                    <Text fontSize="md" color="gray.500">
                      No tenants available.
                    </Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
  
        <Flex justify="space-between" px="20px" py="10px">
          <Button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            variant="outline"
            leftIcon={<MdChevronLeft />}
            aria-label="Previous Page"
          >
            Previous
          </Button>
          <Text>
            Page {pageIndex + 1} of {table.getPageCount()}
          </Text>
          <Button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            variant="outline"
            rightIcon={<MdChevronRight />}
            aria-label="Next Page"
          >
            Next
          </Button>
        </Flex>
  
        {/* Confirmation Modal */}
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Deletion</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Are you sure you want to delete "{itemToDelete?.name}"?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} aria-label="Cancel Deletion">
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  if (itemToDelete) {
                    performDelete(itemToDelete);
                  }
                }}
                isLoading={deletingItemId === itemToDelete?.id}
                loadingText="Deleting"
                aria-label="Confirm Deletion"
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Card>
    );
  }
  