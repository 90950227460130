// BigButton.tsx
import React from 'react';
import { Box, Button, Image } from '@chakra-ui/react';

interface BigButtonProps {
  imageSrc: string;
  label: string;

  href?: string;
  imageOnClick?: () => void;
  onClick?: () => void;
}

const BigButton: React.FC<BigButtonProps> = ({
  imageSrc,
  label,
  href,
  imageOnClick,
  onClick,
}) => {
  return (
    <Box width="250px" textAlign="center">
      {href ? (
        <Box
          as="a"
          href={href}
          onClick={imageOnClick}
          display="block"
          cursor="pointer"
        >
          <Image
            src={imageSrc}
            alt={label}
            width="100%"
            objectFit="cover"
          />
        </Box>
      ) : (
        <Box
          as="div"
          onClick={imageOnClick}
          display="block"
          cursor="pointer"
        >
          <Image
            src={imageSrc}
            alt={label}
            width="100%"
            objectFit="cover"
          />
        </Box>
      )}

      {href ? (
        <Button
          as="a"
          href={href}
          onClick={onClick}
          width="100%"
          mt={2}
          bg="brand.500"
          color="white"
          _hover={{ bg: 'brand.600' }}
        >
          {label}
        </Button>
      ) : (
        <Button
          as="button"
          onClick={onClick}
          width="100%"
          mt={2}
          bg="brand.500"
          color="white"
          _hover={{ bg: 'brand.600' }}
        >
          {label}
        </Button>
      )}
    </Box>
  );
};

export default BigButton;
