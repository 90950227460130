import React from 'react';
import { Flex, Spinner, Text, VStack } from '@chakra-ui/react';

interface LoadingProps {
  message?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({
  message = 'Loading...',
  size = 'xl',
  color = 'brand.500',
}) => {
  return (
    <Flex justify="center" align="center" height="100vh">
      <VStack spacing={4}>
        <Spinner size={size} color={color} />
        <Text fontSize="lg" color="gray.600">
          {message}
        </Text>
      </VStack>
    </Flex>
  );
};

export default Loading;