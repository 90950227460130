export const exampleCategoriesTableData = [
    {
        id: 1,
        name: 'Ink Cartridges',
        tenant_id: 1,
        created_at: '2023-01-01',
        updated_at: '2023-01-01',
    },
    {
        id: 2,
        name: 'Cleaning Supplies',
        tenant_id: 1,
        created_at: '2023-01-01',
        updated_at: '2023-01-01',
    },
    {
        id: 3,
        name: 'Ink',
        tenant_id: 1,
        created_at: '2023-01-01',
        updated_at: '2023-01-01',
    },
];