// src/components/dataDisplay/ItemTable.tsx

import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Stack,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { MdEdit, MdDelete, MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { useApi } from 'components/hooks/useApi';
import EditItemModal from 'views/admin/inventory/allitems/components/editItemModal';
import { Item } from 'components/types/items';

  interface RowObj {
  id: number;
  name: string;
  category_name: string;
  quantity: number;
  cost_price: number;
  selling_price: number;
  min_level: number;
  expiry_date: string;
  batch_number: string;
  tenant_id: number;
  supplier_id: number;
  created_at: string;
  updated_at: string;
}

interface ItemTableProps {
  tableData: RowObj[];
  tableName: string;
  accessToken: string;
  tenant: string | number;
  refreshItems: () => void;
  fakeSave?: boolean;
}

const columnHelper = createColumnHelper<RowObj>();

export default function ItemTable({
  tableData,
  tableName,
  accessToken,
  tenant,
  refreshItems,
  fakeSave,  
}: ItemTableProps): JSX.Element {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<RowObj[]>([]);
  const [globalFilter, setGlobalFilter] = useState('');
   const [pagination, setPagination] = useState({
       pageIndex: 0,
       pageSize: 10,
     })
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const toast = useToast();
  const apiUrl = useApi();
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [editValues, setEditValues] = useState<Partial<RowObj>>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [itemToDelete, setItemToDelete] = useState<RowObj | null>(null);
  const [deletingItemId, setDeletingItemId] = useState<number | null>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<Item | null>(null);

  useEffect(() => {
    if (Array.isArray(tableData)){
      setData(tableData);
    } else {
      setData([]);
    }
  }, [tableData]);

  const handleDeleteClick = (item: RowObj) => {
    setItemToDelete(item);
    onOpen();
  };

  const performDelete = async (item: RowObj): Promise<void> => {
    if (fakeSave) {
      toast({
        title: 'Item deleted successfully - example response',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setDeletingItemId(item.id);
    try {
      const payload = {
        items: [{ id: item.id }],
      };

      const response = await axios.delete(`${apiUrl}/inventory/items`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: {
          tenant: tenant, 
        },
        data: payload, 
      });

      if (response.status === 204) {
        setData((prevData) => prevData.filter((row) => row.id !== item.id));

        toast({
          title: 'Item deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Deletion failed');
      }
    } catch (error: unknown) {
      console.error(error);
      toast({
        title: 'Deletion Failed',
        description:
          axios.isAxiosError(error) && error.response?.data?.message
            ? error.response.data.message
            : 'An error occurred while deleting the item.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDeletingItemId(null);
      onClose();
      setItemToDelete(null);
    }
  };

  const handleEditClick = (item: Item) => {
    setItemToEdit(item);
  setEditModalOpen(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof RowObj
  ) => {
    const rawValue = e.target.value;
    let newValue: string | number = rawValue;
  
    if (field === 'quantity' || field === 'min_level') {
      newValue = rawValue ? parseInt(rawValue, 10) : 0;
    } else if (field === 'cost_price' || field === 'selling_price') {
      newValue = rawValue ? parseFloat(rawValue) : 0;
    }
  
    setEditValues((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditValues({});
  };

  const handleSave = async (id: number) => {
    if (fakeSave) {
      toast({
        title: 'Item updated successfully - example response',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const numericEditValues = {
        ...editValues,
        quantity: editValues.quantity ? Number(editValues.quantity) : 0,
        min_level: editValues.min_level ? Number(editValues.min_level) : 0,
        cost_price: editValues.cost_price ? Number(editValues.cost_price) : 0,
        selling_price: editValues.selling_price ? Number(editValues.selling_price) : 0,
      };
    
      const payload = {
        items: [
          {
            ...numericEditValues,
            id: id,
          },
        ],
      };

      const response = await axios.put(`${apiUrl}/inventory/items`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json', 
        },
        params: {
          tenant: tenant,
        },
      });

      if (response.status === 200) {
        setData((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, ...editValues } : item
          )
        );

        toast({
          title: 'Item updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        setEditingRowId(null);
        setEditValues({});
      } else {
        throw new Error('Update failed');
      }
    } catch (error: unknown) {
      console.error(error);
      toast({
        title: 'Failed to update item',
        description:
          axios.isAxiosError(error) && error.response?.data?.message
            ? error.response.data.message
            : 'An error occurred while updating the item.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Item Name',
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              value={editValues.name ?? ''}
              onChange={(e) => handleChange(e, 'name')}
              aria-label="Edit Item Name"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {info.getValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('quantity', {
        id: 'quantity',
        header: 'Quantity',
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              type="number"
              value={editValues.quantity ?? ''}
              onChange={(e) => handleChange(e, 'quantity')}
              aria-label="Edit Quantity"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {info.getValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('category_name', {
        id: 'category_name',
        header: 'Category Name',
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              value={editValues.category_name ?? ''}
              onChange={(e) => handleChange(e, 'category_name')}
              aria-label="Edit Category Name"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {info.getValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('batch_number', {
        id: 'batch_number',
        header: 'Batch Number',
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              value={editValues.batch_number ?? ''}
              onChange={(e) => handleChange(e, 'batch_number')}
              aria-label="Edit Batch Number"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {info.getValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('expiry_date', {
        id: 'expiry_date',
        header: 'Expiry Date',
        cell: (info) => {
          const rowId = info.row.original.id;
          const isEditing = rowId === editingRowId;
          return isEditing ? (
            <Input
              size="sm"
              type="date"
              value={
                editValues.expiry_date
                  ? new Date(editValues.expiry_date).toISOString().split('T')[0]
                  : ''
              }
              onChange={(e) => handleChange(e, 'expiry_date')}
              aria-label="Edit Expiry Date"
            />
          ) : (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {new Date(info.getValue()).toLocaleDateString()}
            </Text>
          );
        },
      }),
      // -----------
      // Edit Column
      // -----------
      columnHelper.display({
        id: 'edit',
        header: 'Edit',
        cell: (info) => {
          const rowData = info.row.original;
          const rowId = rowData.id;
          const isEditing = rowId === editingRowId;

          if (isEditing) {
            return (
              <Stack direction="row" spacing={2}>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => handleSave(rowId)}
                  aria-label="Save Changes"
                >
                  Save
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={handleCancel}
                  aria-label="Cancel Editing"
                >
                  Cancel
                </Button>
              </Stack>
            );
          } else {
            return (
              <Icon
                as={MdEdit}
                w="20px"
                h="20px"
                cursor="pointer"
                color={textColor}
                onClick={() => handleEditClick(rowData)}
                aria-label={`Edit ${rowData.name}`}
              />
            );
          }
        },
      }),
      // -----------
      // Delete Column
      // -----------
      columnHelper.display({
        id: 'delete',
        header: 'Delete',
        cell: (info) => {
          const rowData = info.row.original;
          return (
            <Icon
              as={MdDelete}
              w="20px"
              h="20px"
              cursor="pointer"
              color="red.500"
              onClick={() => handleDeleteClick(rowData)}
              aria-label={`Delete ${rowData.name}`}
            />
          );
        },
      }),
    ],
    [textColor, editingRowId, editValues]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX="auto">
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {tableName}
        </Text>
        <Menu />
      </Flex>

      <Flex px="25px" py="10px" justifyContent="left" align="center">
        <Input
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          style={{
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
          aria-label="Search Items"
        />
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} borderColor={borderColor}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} borderColor={borderColor}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={table.getAllLeafColumns().length} textAlign="center">
                <Text fontSize="md" color="gray.500">
                  No items available.
                </Text>
              </Td>
            </Tr>
          )}
          </Tbody>
        </Table>
      </Box>

      <Flex justify="space-between" px="20px" py="10px">
        <Button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          variant="outline"
          leftIcon={<MdChevronLeft />}
          aria-label="Previous Page"
        >
          Previous
        </Button>
        <Text>
          Page {pagination.pageIndex + 1} of {table.getPageCount()}
        </Text>
        <Button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          variant="outline"
          rightIcon={<MdChevronRight />}
          aria-label="Next Page"
        >
          Next
        </Button>
      </Flex>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete "{itemToDelete?.name}"?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose} aria-label="Cancel Deletion">
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                if (itemToDelete) {
                  performDelete(itemToDelete);
                }
              }}
              isLoading={deletingItemId === itemToDelete?.id}
              loadingText="Deleting"
              aria-label="Confirm Deletion"
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isEditModalOpen && itemToEdit && (
      <EditItemModal
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        item={itemToEdit}
        accessToken={accessToken}
        tenant={tenant}
        refreshItems={refreshItems}
      />
    )}
    </Card>
  );
}
