import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import TenantTable from 'components/dataDisplay/tenantTable';
import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useApi } from 'components/hooks/useApi';
import { useTenant } from 'components/hooks/useTenant';
import { useToken } from 'components/hooks/useToken';
import { Tenant, TenantsResponse } from 'components/types/tenants';
import { SimpleGrid } from '@chakra-ui/react';
import AddTenant from './components/addTenant';

export default function TenantManagement() {
	const accessToken = useToken();
	const tenant = useTenant();
	const apiUrl = useApi();
	const [tableData, setTableData] = useState<Tenant[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	
	useEffect(() => {
	  if (!accessToken || !tenant) return; // Ensure accessToken and tenant are available
	  const fetchData = async (): Promise<void> => {
		try {
		  const response = await axios.get<TenantsResponse>(
			`${apiUrl}/tenants`,
			{
			  headers: {
				Authorization: `Bearer ${accessToken}`,
			  },
			  params: {
				tenant: tenant,
			  },
			}
		  );
		  setTableData(response.data.data.Tenants);
		  setLoading(false);
		} catch (err: unknown) {
		  if (axios.isAxiosError(err)) {
			setError(err.response?.data?.message || "An error occurred while fetching data");
		  } else if (err instanceof Error) {
			setError(err.message);
		  } else {
			setError("An unknown error occurred");
		  }
		  setLoading(false);
		}
	  };
	
	  fetchData();
	}, [accessToken, tenant, apiUrl]);
  
	if (loading) {
	  return <Loading message="Fetching items..." />;
	}
  
	if (error) return <p>Error: {error}</p>;
  
	return (
	  <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
		
		<TenantTable
		  tableData={tableData} 
		  tableName="Inventory" 
		  accessToken={accessToken} 
		  tenant={tenant} 
		/>
<AddTenant accessToken={accessToken} tenant={tenant} />
	  </SimpleGrid>
	);
}
