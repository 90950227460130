// DocumentationPage.tsx
import  { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  UnorderedList,
  ListItem,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';

// Import each doc page component
import IntroductionPage from './components/IntroductionPage';
import SetupPage from './components/SetupPage';
import CoreConceptsPage from './components/CoreConceptsPage';
import JobsPage from './components/JobsPage';
import OrdersDocPage from './components/OrdersPage';
import InventoryPage from './components/InventoryPage';
import StockPage from './components/StockPage';

type DocKey = 'introduction' | 'setup' | 'core-concepts' | 'jobs' | 'stock' | 'inventory' | 'orders';

export default function DocumentationPage() {
  const [selectedDoc, setSelectedDoc] = useState<DocKey>('introduction');
  const cardBg = useColorModeValue('white', 'navy.800');
  const headingColor = useColorModeValue('brand.500', 'white');
  let CurrentDocComponent: JSX.Element;
  switch (selectedDoc) {
    case 'setup':
      CurrentDocComponent = <SetupPage />;
      break;
    case 'core-concepts':
      CurrentDocComponent = <CoreConceptsPage />;
      break;
    case 'introduction':
      CurrentDocComponent = <IntroductionPage />;
      break;
    case 'jobs':
      CurrentDocComponent = <JobsPage />;
      break;
    case 'orders':
      CurrentDocComponent = <OrdersDocPage />;
      break;
    case 'inventory':
      CurrentDocComponent = <InventoryPage />;
      break;
    case 'stock':
      CurrentDocComponent = <StockPage />;
      break;
    default:
      CurrentDocComponent = <IntroductionPage />;
      break;
  }

  return (
    <SimpleGrid
    columns={{ base: 1, md: 2 }}
    templateColumns={{ base: "1fr", md: "1fr 2fr" }}
    spacing={6}
    p={{ base: 4, md: 10 }}
    minH="100vh"
    mt="50px"
    >
      <Card bg={cardBg} borderRadius="20px" boxShadow="md" height="fit-content">
        <CardHeader pb="0">
          <Heading as="h3" size="md" color={headingColor} mb={2}>
            Table of Contents
          </Heading>
        </CardHeader>
        <CardBody>
          <UnorderedList spacing={3} ml={4}>
            <ListItem
              cursor="pointer"
              onClick={() => setSelectedDoc('introduction')}
            >
              Introduction
            </ListItem>
            <ListItem cursor="pointer" onClick={() => setSelectedDoc('setup')}>
              Setup
            </ListItem>
            <ListItem
              cursor="pointer"
              onClick={() => setSelectedDoc('core-concepts')}
            >
              Core Concepts
            </ListItem>
            <ListItem
              cursor="pointer"
              onClick={() => setSelectedDoc('inventory')}
            >
              Inventory
            </ListItem>
            <ListItem
              cursor="pointer"
              onClick={() => setSelectedDoc('jobs')}
            >
              Jobs
            </ListItem>
            <ListItem
              cursor="pointer"
              onClick={() => setSelectedDoc('orders')}
            >
              Orders
            </ListItem>
            <ListItem
              cursor="pointer"
              onClick={() => setSelectedDoc('stock')}
            >
              Stock
            </ListItem>
          </UnorderedList>
        </CardBody>
      </Card>

      {/* RIGHT COLUMN: The "selected" Doc Page */}
      <Card bg={cardBg} borderRadius="20px" boxShadow="md">
        <CardHeader>
          <Heading as="h3" size="md" color={headingColor}>
            In-App Documentation
          </Heading>
        </CardHeader>
        <CardBody>
          {CurrentDocComponent}
        </CardBody>
      </Card>
    </SimpleGrid>
  );
}
