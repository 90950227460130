import { Card, SimpleGrid } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import QuickLinks from "./components/quickLinks";
import HomeHeader from "./components/homeHeader";
import Notifications from "./components/notifications";
import { useToken } from "components/hooks/useToken";
import { useApi } from "components/hooks/useApi";
import { useTenant } from "components/hooks/useTenant";
export default function HomePage() {

    const token = useToken();
    const apiUrl = useApi();
    const tenant = useTenant();
    return (
        <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
            <HomeHeader />
            <Card px='0px'>
                <QuickLinks />
            </Card>
            <Card px='0px'>
                <Notifications accessToken={token} apiUrl={apiUrl} tenant={tenant}/>
            </Card>
        </SimpleGrid>
    );
}