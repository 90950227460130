// IntroductionPage.tsx
import React from 'react';
import { Box, Heading, Text, useColorModeValue, Image, Stack, Button } from '@chakra-ui/react';
import InventoryDropDownImage from 'assets/img/docs/inventoryDropDown.png';
import AddCategoriesDialog from 'assets/img/docs/addCategoriesDialog.png';
import QuickAddCategory from 'assets/img/docs/quickAddCategory.png';
import ItemTable from 'components/dataDisplay/ItemTable';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
import { exampleItemsTableData } from '../mockdata/exampleItemsTableData';
import CategoriesTable from 'views/admin/inventory/categories/components/categoriesTable';
import { exampleCategoriesTableData } from '../mockdata/exampleCategoriesTableData';
const InventoryPage: React.FC = () => {
  const headingColor = useColorModeValue('gray.700', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const token = useToken();
  const tenant = useTenant();
  return (
    <Box>
      <Heading as="h3" size="lg" color={headingColor} mb={4}>
        Inventory
      </Heading>
      <Text color={textColor} fontSize="md">
        The core of BeeStocked. These are the things that your business owns, for both professional use and for sale to customers. 
        The inventory page itself contains two sub-pages:
      </Text>
      <Stack alignContent={'center'} alignItems={'center'}>
      <Image 
      src={InventoryDropDownImage}
      
      />
      </Stack>
      <Text color={textColor} fontSize="lg" mb={4}>
        <strong> All Items</strong>
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        This page will allow you to see the entirety of your inventory, including batch numbers, quantities, expiry dates and categories. 
        It will also display the total inventory value, how many items have been consumed this month, the total number of items you have.  
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        <strong>Actions available on this page:</strong>
      </Text>
             <Text color={textColor} fontSize="md" mb={4}> <Button
                variant="brand"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: '100px', md: '150px' }}
                h="32px"
                aria-label="Add Item"
              >
                Add New Item Type
              </Button>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
              This button will bring a dialog to allow you to add a new item type for use in your inventory management. This then allows items 
              of this type to be added to your inventory. It will ask you some questions about the new item type, such as its name, category, etc.
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
               <i>Item name, category are required fields; all others are optional.</i>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}> <Button
                variant="brand"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: '100px', md: '150px' }}
                h="32px"
                aria-label="Add Item"
              >
                Add Supplier
              </Button>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
              This button will bring a dialog to allow you to add a new supplier. You can then use this supplier for adding item types, adding orders etc.
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
               <i>Supplier name, is a required field; all others are optional.</i>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}> <Button
                variant="brand"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: '100px', md: '150px' }}
                h="32px"
                aria-label="Add Item"
              >
                Add Categories
              </Button>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
              This button will bring a dialog to allow you to add a new category(s). For multiple please enter a new one per line such as in this example:
              <Stack alignContent={'center'} alignItems={'center'}>
              <Image src={AddCategoriesDialog}></Image>
              </Stack>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
               <strong> Inventory table</strong>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
              This table will display the inventory items you currently have in your business:
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
                    <ItemTable 
                      tableData={exampleItemsTableData} 
                      tableName="Inventory" 
                      accessToken={token} 
                      tenant={tenant}
                      refreshItems={() => {}}
                    />
                    </Text>
                    <Text color={textColor} fontSize="md" mb={4}>
                      The <strong>EDIT</strong> button will allow you to edit the item (try it out, saving won't work as this is demo data, but you'll see the dialog).
                      You may notice that you aren't able to edit the batch number from this screen, this is due to the fact that this would cause issues within the underlying data.
                      So if you have entered an incorrect batch number, or you simply don't have any of that batch left then please use the DELETE function instead.
                    </Text>
                    <Text color={textColor} fontSize="md" mb={4}>
                      The <strong>DELETE</strong> button will allow you to delete the item (this will work, until you refresh this documentation page)
                    </Text>
                    <Text color={textColor} fontSize="lg" mb={4}>
        <strong> Categories </strong>
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        Categories are the main way to categorise your inventory in BeeStocked. This page will see all the ones you've currently got setup, with a search 
        function at the top. If you search for a category that you don't have, then use the quick-add function to add a new one:
        </Text>
        <Text color={textColor} fontSize="md" mb={4}>
          <Stack alignContent={'center'} alignItems={'center'}>
          <Image src={QuickAddCategory}></Image>
          </Stack>
          </Text>
          <Text color={textColor} fontSize="md" mb={4}>
            Alternatively you can add multiple categories using the same functionality as before:
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
            <Button
                variant="brand"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: '100px', md: '150px' }}
                h="32px"
                aria-label="Add Item"
              >
                Add Multiple
              </Button>
              </Text>
      <Stack alignContent={'center'} alignItems={'center'}>
              <Image src={AddCategoriesDialog}></Image>
              </Stack>
        <Text color={textColor} fontSize="md" mb={4}>
          The categories table will allow you to edit and delete existing categories:
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
      <CategoriesTable 
                      tableData={exampleCategoriesTableData} 
                      accessToken={token} 
                      tenant={tenant}
                      refetchData={() => {}}
                      apiUrl='http://localhost:3000'
                      fakeSave={true}
                    />
   </Text>
   <Text color={textColor} fontSize="md" mb={4}>
    Go ahead, try out the table - see what happens if you search for 'ink'.
   </Text>
    </Box>
  );
};

export default InventoryPage;