import { Box } from "@chakra-ui/react"
import BigButton from "components/buttons/BigButton"
import inventoryImage from "../../../../assets/img/buttons/inventory.png"
import jobsImage from "../../../../assets/img/buttons/jobs.png"
import orderImage from "../../../../assets/img/buttons/order.png"
import stockImage from "../../../../assets/img/buttons/stock.png"

export default function QuickLinks() {
    return (
        <Box
        display="flex" 
        justifyContent="center" 
        gap={4}
        mt={10}
        mb={10}
      >
        <BigButton
          imageSrc={inventoryImage}
          label="Inventory"
          href="/admin/inventory/all"
        />
          <BigButton
            imageSrc={jobsImage}
            label="Jobs"
            href="/admin/jobs/history"
          />
          <BigButton
            imageSrc={orderImage} 
            label="Orders" 
            href="/admin/orders/history" 
            />
          <BigButton 
          imageSrc={stockImage} 
          label="Stock" 
          href="/admin/stock/levels" 
          />
      </Box>
    )
}