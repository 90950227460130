import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Flex,
  Text,
  Spinner,
  IconButton,
  useColorModeValue,
  Button,
  Card,
  CardBody,
} from '@chakra-ui/react';
import axios from 'axios';

// Icons
import {
  MdInfoOutline,
  MdOutlineWarning,
  MdDateRange,
  MdMoveToInbox,
  MdDone,
} from 'react-icons/md';

// Custom Notification component (adjust the import path if needed)
import Notification from 'components/dataDisplay/Notification';

interface NotificationsPanelProps {
  accessToken?: string;
  tenant?: number | string;
  apiUrl?: string;
}

interface NotificationData {
  id: number;
  message: string;
  type: string;
  category: string;      // e.g. "expiring item", "low stock item", etc.
  created_at: string;    // date/time string
  updated_at: string;
  is_read: boolean;
}

export default function NotificationsPanel({
  accessToken,
  tenant,
  apiUrl,
}: NotificationsPanelProps) {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(true);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const cardBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const fetchNotifications = useCallback(async () => {
    if (!accessToken || !tenant || !apiUrl) return;
    setLoading(true);

    try {
      const res = await axios.get<{ data: { Notifications: NotificationData[] } }>(
        `${apiUrl}/notifications`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { tenant },
        }
      );
      console.log("recieved notifications", res.data.data.Notifications)
      setNotifications(res.data.data.Notifications || []);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  }, [accessToken, tenant, apiUrl]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  // Toggle whether to display unread or all notifications
  const handleToggleUnread = () => {
    setShowUnreadOnly((prev) => !prev);
  };

  // Mark all notifications as read
  const handleMarkAllRead = async () => {
    if (!accessToken || !tenant || !apiUrl) return;

    try {
      await axios.get(`${apiUrl}/notifications/markAllAsRead`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant },
      });
      fetchNotifications();
    } catch (error) {
      console.error('Error marking all as read:', error);
    }
  };

  // Mark a single notification as read
  const handleMarkAsRead = async (notificationId: number) => {
    if (!accessToken || !tenant || !apiUrl) return;

    try {
      await axios.get(`${apiUrl}/notifications/markAsRead`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { tenant, notification_id: notificationId },
      });
      fetchNotifications();
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  // Simple mapping to icons based on category
  function getIconForCategory(type?: string) {
    if (!type) return <MdInfoOutline />;
    switch (type.toLowerCase()) {
      case 'expiring item':
        return <MdDateRange />;
      case 'low stock item':
        return <MdMoveToInbox />;
      case 'warning':
        return <MdOutlineWarning />;
      default:
        return <MdInfoOutline />;
    }
  }

  // Filter notifications if "unread only" is toggled
  const displayedNotifications = showUnreadOnly
    ? notifications.filter((n) => !n.is_read)
    : notifications;

  return (
    <Card
      bg={cardBg}
      boxShadow={shadow}
      borderRadius="20px"
      p={0}
      w={{ base: '100%', md: 'auto' }}
    >
      <CardBody>
        {/* Header Row: Title + Show All/Show Unread + Mark All Read */}
        <Flex
          w="100%"
          mb="20px"
          align="center"
          justify="space-between"
          textAlign="left"
        >
          <Text fontSize="lg" fontWeight="700" color={textColor}>
            Notifications
          </Text>

          <Flex gap={3}>
            {/* Toggle Show Unread/All */}
            <Button
              variant="link"
              color={textColorBrand}
              onClick={handleToggleUnread}
              size="sm"
            >
              {showUnreadOnly ? 'Show All' : 'Show Unread'}
            </Button>

            {/* Mark All Read */}
            <Button
              variant="link"
              color={textColorBrand}
              onClick={handleMarkAllRead}
              size="sm"
            >
              Mark all read
            </Button>
          </Flex>
        </Flex>

        {/* Main content: list of notifications or spinner */}
        {loading ? (
          <Flex justify="center" p="10px">
            <Spinner />
          </Flex>
        ) : (
          <Box
            // Make it scrollable if needed
            maxH="400px"
            overflowY="auto"
          >
            {displayedNotifications.map((notif) => {
              const createdDate = new Date(notif.created_at).toLocaleString();

              return (
                <Flex
                  key={notif.id}
                  align="center"
                  justify="space-between"
                  px="0"
                  py="8px"
                  borderRadius="8px"
                  mb="10px"
                >
                  <Notification
                    date={createdDate}
                    icon={getIconForCategory(notif.category)}
                    message={notif.message}
                    category={notif.category}
                    is_read={notif.is_read}
                  />
                  {/* If unread, show icon to mark as read */}
                  {!notif.is_read && (
                    <IconButton
                      aria-label="Mark as read"
                      icon={<MdDone />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleMarkAsRead(notif.id)}
                    />
                  )}
                </Flex>
              );
            })}

            {displayedNotifications.length === 0 && !loading && (
              <Box textAlign="center" p="10px">
                <Text fontSize="sm" color={textColor}>
                  No notifications
                </Text>
              </Box>
            )}
          </Box>
        )}
      </CardBody>
    </Card>
  );
}
