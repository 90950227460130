// src/views/admin/orderHistory/components/orderHistoryTable.tsx
import React, { useState, useEffect } from "react";
import {
  Text,
  Box,
  Flex,
  SimpleGrid,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import IncomingOrder from "components/dataDisplay/IncomingOrder";
import OrderDetailsModal from "./orderDetailsModal";
import { Order } from "components/types/orders";
import { Supplier } from "components/types/suppliers";
import { useApi } from "components/hooks/useApi";
import { useToken } from "components/hooks/useToken";
import { useTenant } from "components/hooks/useTenant";
import axios from "axios";
import { OrderItem } from "components/types/orders";

interface OrderHistoryTableProps {
  orders: Order[];
  suppliers: Supplier[];
  fakeSave?: boolean,
}

export default function OrderHistoryTable({
  orders,
  suppliers,
  fakeSave,
}: OrderHistoryTableProps) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filteredOrders, setFilteredOrders] = useState<Order[]>(orders);
  const [itemsMap, setItemsMap] = useState<{ [key: number]: string }>({});
  const [orderItemsMap, setOrderItemsMap] = useState<{ [key: number]: string[] }>({});

  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const apiUrl = useApi();
  const accessToken = useToken();
  const tenant = useTenant();

  useEffect(() => {
    const fetchItemsForMap = async () => {
      if (!accessToken || !tenant) return;
      try {
        const response = await axios.get<{ data: { Items: Array<{ id: number; name: string }> } }>(
          `${apiUrl}/inventory/items`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { tenant },
          }
        );
        const itemsArray = response.data.data.Items;
        const map: { [key: number]: string } = {};
        itemsArray.forEach(item => {
          map[item.id] = item.name;
        });
        setItemsMap(map);
      } catch (err) {
        console.error("Error fetching items for search mapping:", err);
      }
    };
    fetchItemsForMap();
  }, [accessToken, tenant, apiUrl]);

  useEffect(() => {
    const fetchAllOrderItems = async () => {
      if (!accessToken || !tenant || orders.length === 0) return;
  
      try {
        const fetchPromises = orders.map(order =>
          axios
            .get<{ data: { OrderItems: OrderItem[] } }>(`${apiUrl}/order_items`, {
              headers: { Authorization: `Bearer ${accessToken}` },
              params: { tenant, order_id: order.id },
            })
            .then(response => ({
              orderId: order.id,
              orderItems: response.data.data.OrderItems || []
            }))
        );
  
        const responses = await Promise.all(fetchPromises);
        const map: { [key: number]: string[] } = {};
  
        responses.forEach(({ orderId, orderItems }) => {
          orderItems.forEach(item => {
            const itemName = itemsMap[item.item_id] || "";
            if (!map[orderId]) {
              map[orderId] = [];
            }
            map[orderId].push(itemName);
          });
        });
  
        setOrderItemsMap(map);
      } catch (err) {
        console.error("Error fetching order items for mapping:", err);
      }
    };
  
    if (Object.keys(itemsMap).length > 0 && orders.length > 0) {
      fetchAllOrderItems();
    }
  }, [accessToken, tenant, apiUrl, itemsMap, orders]);
  

  React.useEffect(() => {
    const safeOrders = orders || [];
    const safeSuppliers = suppliers || [];
    
    const filtered = safeOrders.filter(order => {
      const supplierName = safeSuppliers.find(s => s.id === order.supplier_id)?.name || "";
      
      const orderMatches =
      order.id.toString().includes(globalFilter) ||
      supplierName.toLowerCase().includes(globalFilter.toLowerCase()) ||
      order.status.toLowerCase().includes(globalFilter.toLowerCase());
      
      const itemNames = orderItemsMap[order.id] || [];
      const itemMatches = itemNames.some(name =>
        name.toLowerCase().includes(globalFilter.toLowerCase())
      );
      
      
      return orderMatches || itemMatches;
    });

    filtered.sort((a, b) => {
      const dateA = new Date(a.due_date).valueOf();
      const dateB = new Date(b.due_date).valueOf();
      return dateB - dateA; 
    });
  
    setFilteredOrders(filtered);
    setPageIndex(0);
  }, [globalFilter, orders, suppliers, orderItemsMap]);

  const start = pageIndex * pageSize;
  const end = start + pageSize;
  const pageData = filteredOrders.slice(start, end);
  const pageCount = Math.ceil(filteredOrders.length / pageSize);
  const selectedOrder = orders.find((o) => o.id === selectedOrderId);
  const selectedOrderStatus = selectedOrder ? selectedOrder.status : "Pending";
  return (
    <>
      <Box pt={{ base: "130px", md: "40px", xl: "40px" }}>
        <Box mb="10px">
          <SearchBar
            value={globalFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value)}
            h="44px"
            w={{ lg: "390px" }}
            borderRadius="16px"
            placeholder="Search by order ID, supplier, status, or item..."
          />
        </Box>

        <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
          {pageData.map((order, index) => {
            const supplierName = suppliers.find(s => s.id === order.supplier_id)?.name || "Unknown";
            const now = new Date();
            const dueDate = new Date(order.due_date);
            const isOverdue = dueDate < now;
            // Override status to "overdue" if necessary
            const effectiveStatus = isOverdue ? "overdue" : order.status;

            return (
              <Box
                key={order.id} 
                onClick={() => {
                  if (!fakeSave) {
                    setSelectedOrderId(order.id);
                    openModal();
                  }
                }}
                cursor="pointer"
              >
                <IncomingOrder
                  date={new Date(order.order_date).toLocaleDateString()}
                  status={effectiveStatus}  // Pass overridden status
                  name={supplierName}
                  supplier={supplierName}
                  orderDocumentLink={order.order_document_link || "#"}
                />
              </Box>
            );
          })}
        </SimpleGrid>

        <Flex justify="space-between" align="center">
          <Text>
            Showing {filteredOrders.length === 0 ? 0 : start + 1} to{" "}
            {end > filteredOrders.length ? filteredOrders.length : end} of{" "}
            {filteredOrders.length} entries
          </Text>
          <Flex gap="10px">
            <Button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>
              Prev
            </Button>
            <Button
              onClick={() => setPageIndex(pageIndex + 1)}
              disabled={pageIndex >= pageCount - 1 || pageCount === 0}
            >
              Next
            </Button>
          </Flex>
        </Flex>
      </Box>

      <OrderDetailsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        orderId={selectedOrderId}
        orderStatus={selectedOrderStatus}
      />
    </>
  );
}
