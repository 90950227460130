// IntroductionPage.tsx
import React from 'react';
import { Box, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import AddJobModal from 'views/admin/jobs/components/addJobModal';
import JobHistoryTable from 'views/admin/jobs/components/jobHistoryTable';
import { exampleJobsTableData } from '../mockdata/exampleJobsTableData';
const JobsPage: React.FC = () => {
  const headingColor = useColorModeValue('gray.700', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box>
      <Heading as="h3" size="lg" color={headingColor} mb={4}>
        Jobs
      </Heading>

      <Text color={textColor} fontSize="md" mb={4}>
        The jobs page is where you can tell the system all about the work you're doing and what items you're consuming from your inventory.
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        This page will allow you to see historical jobs, their statuses and the date the job is scheduled for. 
        It will also display the total cost of inventory consumed this month, how many inventory items have been consumed this month, and some other stuff tbc.  
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        <strong>Actions available on this page:</strong>
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        <AddJobModal refreshJobs={() => {}} fakeSave={true}/>
      </Text>
      <Text color={textColor} fontSize="md" mb={4}> 
        This button allows you to enter a new job onto the system. You will need to enter a name for the job, a date and add any consumable
        (non-reusable) items onto the job. Go on, give it a go - it won't save any real data. 
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        The job will be created with a status of 'Open', once you have finished the job you can then 'mark as complete' on the jobs page 
        this will then remove the items added to the job from your inventory.
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        <strong> Jobs history table</strong>
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
      This table will display the historical jobs for your business:
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        <JobHistoryTable jobs={exampleJobsTableData} refreshJobs={() => {}} fakeSave={true} />
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        When clicking items in your job history you will see more details about them, and have the options to Edit, Delete, or mark them as complete.
        Once a job is marked as complete then the appropriate inventory items will be removed from your inventory. This example table doesn't have any 
        items attached to jobs currently.
        </Text>
    </Box>
  );
};

export default JobsPage;