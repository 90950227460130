// OrdersPage.tsx
import React from 'react';
import { Box, Heading, Text, useColorModeValue, Stack, Image, Button } from '@chakra-ui/react';
import OrdersDropDownImage from 'assets/img/docs/ordersDropDown.png';
import AddNewOrderModal from 'assets/img/docs/addNewOrderModal.png';
import OrderHistoryTable from 'views/admin/orders/history/components/orderHistoryTable';
import { mockSuppliers, mockOrders } from '../mockdata/exampleOrdersTableData';
import SupplierTable from 'components/dataDisplay/SupplierTable';
import { useToken } from 'components/hooks/useToken';
import { useTenant } from 'components/hooks/useTenant';
const OrdersDocPage: React.FC = () => {
  const headingColor = useColorModeValue('gray.700', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const accessToken = useToken();
  const tenant = useTenant();
  return (
<Box>
      <Heading as="h3" size="lg" color={headingColor} mb={4}>
        Orders
      </Heading>
      <Text color={textColor} fontSize="md" mb={4}>
        Orders are the main way to replenish your inventory in BeeStocked. These are attached to <strong>suppliers</strong> and contain <strong>items</strong>.
        The orders page contains two sub-pages:
      </Text>
      <Stack alignContent={'center'} alignItems={'center'}>
      <Image 
      src={OrdersDropDownImage}
      
      />
      </Stack>
      <Text color={textColor} fontSize="lg" mb={4}>
        <strong> Order History</strong>
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        This page will allow you to see the your order history, sorted by due date, and allow you to mark items as receieved when they get delivered. 
        It will also display the total monthly order value, the value of orders awaiting delivery, number of items incoming and also the total number of 
        items ordered this month.  
      </Text>
      <Text color={textColor} fontSize="md" mb={4}>
        <strong>Actions available on this page:</strong>
      </Text>
             <Text color={textColor} fontSize="md" mb={4}> <Button
                variant="brand"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: '100px', md: '150px' }}
                h="32px"
                aria-label="Add New Order"
              >
                Add New Order
              </Button>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
              This button will bring a dialog to allow you to add a new order onto the system. It will require you to select a supplier, the order date,
              the due dates and then add the items to the order. When adding items to the order it will tell you the cost of that item in the system, but 
              you can override that if it's different for more accurate results. From the Add New Order dialog you can also add new suppliers and new item 
              types to save you having to change screens to do this:
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
               <Image src={AddNewOrderModal} />
              </Text>
              
              <Text color={textColor} fontSize="md" mb={4}>
               <strong> Orders table</strong>
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
              This table will display the historical orders you have previously created:
              </Text>
              <OrderHistoryTable orders={mockOrders} suppliers={mockSuppliers} fakeSave={true}/>
              <Text color={textColor} fontSize="md" mb={4}>
              </Text>
              <Text color={textColor} fontSize="lg" mb={4}>
              <strong> Suppliers</strong>
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
              This page will allow you to see and search through the suppliers you have in BeeStocked. 
            </Text>
            <Text color={textColor} fontSize="md" mb={4}>
                      <SupplierTable 
                        tableData={mockSuppliers} 
                        tableName="Suppliers" 
                        refreshSuppliers={() => {}}
                        accessToken={accessToken}
                        tenant={tenant}
                        fakeSave={true}
                      />
            </Text>
    </Box>
  );
};

export default OrdersDocPage;