// hooks/useTenant.ts
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export function useTenant() {
  const { getIdTokenClaims } = useAuth0();
  const [tenant, setTenant] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchTenant = async () => {
      try {
        const claims = await getIdTokenClaims();
        const extractedTenant =
          claims?.['https://beeinked.co/claims/app_metadata']?.tenant || null;
        if (isMounted) {
          setTenant(extractedTenant);
        }
      } catch (err) {
        console.error('Error fetching tenant:', err);
      }
    };

    fetchTenant();
    return () => {
      isMounted = false;
    };
  }, [getIdTokenClaims]);

  return tenant;
}
