import React from 'react';
import { Box, Heading, Text, useColorModeValue, Image } from '@chakra-ui/react';
import BusinessSettingsImage from 'assets/img/docs/businessSettings.png';

const SetupPage: React.FC = () => {
  const headingColor = useColorModeValue('gray.700', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box>
      <Heading as="h3" size="lg" color={headingColor} mb={4}>
        Setup
      </Heading>
      <Text color={textColor} fontSize="md">
        The initial setup of BeeStocked should be mostly done for you, but we would like to draw your attention to a couple of things in the 'Settings -&gt; Business Settings'
        section:
        </Text>
        <Text color={textColor} fontSize="lg" mb={4}>
        <Image src={BusinessSettingsImage} w="100%" h="100%" />
        </Text>
      <Text color={textColor} fontSize="md" mb={4}>
             First - ensure that your `notification email` is correctly set, as otherwise you might miss out on important things!
             </Text>
      <Text color={textColor} fontSize="md" mb={4}>
              Secondly - ensure your Timezone and Currency are correctly set, otherwise things might look a little strange to you and calculations will be off.
      </Text>
    </Box>
  );
};

export default SetupPage;