// src/views/admin/jobs/components/JobHistoryTable.tsx
import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { Job } from 'components/types/jobs';
import JobDetailsModal from './jobDetailsModal';

interface JobHistoryTableProps {
  jobs: Job[];
  refreshJobs: () => void;
}

export default function JobHistoryTable({ jobs, refreshJobs }: JobHistoryTableProps) {
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      {jobs.map((job) => (
        <Flex
          key={job.id}
          onClick={() => {
            setSelectedJob(job);
            onOpen();
          }}
          cursor="pointer"
          p="4"
          borderWidth="1px"
          borderRadius="md"
          mb="2"
          align="center"
          justify="space-between"
        >
          <Text fontWeight="bold">{job.name}</Text>
          <Text>{job.status}</Text>
          <Text>{new Date(job.date).toLocaleDateString()}</Text>
        </Flex>
      ))}

      <JobDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        job={selectedJob}
        refreshJobs={refreshJobs}
      />
    </Box>
  );
}
