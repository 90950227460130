// DayDateField.tsx
import React, { forwardRef } from 'react';
import {
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  InputProps,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DayDateFieldProps extends Omit<InputProps, 'value' | 'onChange'> {
  id?: string;
  label?: string;
  extra?: React.ReactNode;
  placeholder?: string;
  mb?: string | number;
  value: Date | null; 
  onChange: (date: Date | null) => void;
}

const DayDateField: React.FC<DayDateFieldProps> = (props) => {
  const {
    id,
    label,
    extra,
    placeholder,
    mb,
    value,
    onChange,
    ...rest
  } = props;

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const CustomInput = forwardRef<HTMLInputElement, any>(
    ({ value: _val, onClick }, ref) => (
      <Input
        onClick={onClick}
        ref={ref}
        value={_val}
        placeholder={placeholder}
        readOnly
        {...rest}
      />
    )
  );
  CustomInput.displayName = 'CustomInput';

  return (
    <Flex direction="column" mb={mb ?? '30px'}>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
        {extra && (
          <Text fontSize="sm" fontWeight="400" ms="2px">
            {extra}
          </Text>
        )}
      </FormLabel>
      <DatePicker
        id={id}
        selected={value}
        onChange={(date: Date | null) => onChange(date)}
        customInput={<CustomInput />}
        dateFormat="yyyy/MM/dd" 
      />
    </Flex>
  );
};

export default DayDateField;
